import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Grid, Rating, Box, Stack, Typography, Card } from '@mui/material';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';
import { DefaultResumit } from '../assets/defaultResumit';
import BuyForm from './buyForm/BuyForm.component';
import { getIdForPopUp, getOneResumit, commentInResumit, setRatingInResumit } from '../firebase/firebase.utils';
// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
  1: 'Como la tula',
  2: 'La wea mala',
  3: 'Piola tu wea',
  4: 'Like it',
  5: 'Me salvaste el semestre',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function ReviewDialog() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [mostrarPopUp, setMostrarPopUp] = useState(false);
  const [resumit, setResumit] = useState(DefaultResumit);
  const [comment, setComment] = useState({});
  const [commentText, setCommentText] = useState('');
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setComment((values) => ({ ...values, [name]: value }));
    setCommentText(event.target.commentText);
  };

  const handleSubmitComment = async () => {
    comment.nameOfComment = currentUser.displayName;
    comment.dayOfComment = new Date();
    if (!comment.rating) {
      comment.rating = 'no';
    }
    if (!comment.typeOfComment) {
      comment.typeOfComment = 'Dudas';
    }
    commentInResumit(resumit, comment);
    setCommentText('');
  };

  const handleRatingForm = async (nota) => {
    setRatingInResumit(resumit.id, nota, currentUser.uid);
  };
  const getData = async () => {
    const id = await getIdForPopUp(currentUser);
    if (id !== undefined) {
      const resumitForShow = await getOneResumit(id);
      setResumit(resumitForShow);
      setMostrarPopUp(true);
    }
  };
  useEffect(() => {
    getData();
  }, [currentUser]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setMostrarPopUp(false);
  };

  return (
    <div>
      <Dialog
        open={mostrarPopUp}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
      >
        <Stack justifyContent={'center'} direction="row" alignItems={'center'} paddingTop={1}>
          <Typography variant="h5" textAlign={'center'}>
            {'Que tal te pareció '}
          </Typography>
          <Typography paddingLeft={1} display={'inline'} variant="h4" color="primary">
            {resumit.name}?
          </Typography>
        </Stack>
        <DialogContent>
          <Grid paddingTop={4} container justifyContent={'center'} spacing={2}>
            {/* {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>} */}
            <Grid item p={2} xs={12} md={5}>
              <ShopProductCard product={resumit} inSuccessPage />
            </Grid>
            <Grid item xs={12} md={7}>
              <Card sx={{ marginBottom: 2 }}>
                <Stack spacing={2} direction="column" alignItems={'center'}>
                  <Typography variant="h5"> Califica el Resumit!</Typography>
                  <Rating
                    getLabelText={getLabelText}
                    name="simple-controlled"
                    sx={{ fontSize: '3rem', textAlign: 'center' }}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                      handleRatingForm(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                  />
                  <Typography variant="h6"> Agrega un comentario</Typography>
                  <Stack paddingRight={3} justifyContent="space-between">
                    <TextField
                      sx={{ maxWidth: 800, marginLeft: 2, marginBlockEnd: 1 }}
                      fullWidth
                      value={commentText}
                      id="textComment"
                      label="Tu comentario"
                      name="textComment"
                      multiline
                      maxRows={6}
                      onChange={handleChangeForm}
                    />
                    <Button variant="contained" onClick={handleSubmitComment} sx={{ marginLeft: 4, marginY: 1 }}>
                      Comentar
                    </Button>
                  </Stack>
                </Stack>
              </Card>
              <BuyForm product={resumit} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
