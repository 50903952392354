/* eslint-disable arrow-body-style */
import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import ResumitDownloadedCard from '../sections/@dashboard/products/ResumitDownloadedCard';
import { getOneResumit, getDataOfUser } from '../firebase/firebase.utils';
import LoginFirst from '../components/loginFirst/LoginFirst';
import Spinner from '../components/spinner/spinner';
import ReviewDialog from '../components/dialogReviewResumit';

const MyResumitsDownloaded = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [myDownloadedFilesIds, setDownloadedFilesIds] = useState([]);
  const [myDownloadedFilesComplete, setDownloadedFilesComplete] = useState([]);
  const [cargando, setCargando] = useState(true);

  const getAndSetData = async () => {
    if (currentUser) {
      const dataSnapshot = await getDataOfUser(currentUser);
      setDownloadedFilesIds(dataSnapshot.data().myDownloadedFiles);
      const checkIfAlreadyExist = [myDownloadedFilesIds];
      if (dataSnapshot.data().myDownloadedFiles.length !== 0) {
        dataSnapshot.data().myDownloadedFiles.map(async (id) => {
          if (id.resumitDownloadedId) {
            id = id.resumitDownloadedId;
          }
          if (!checkIfAlreadyExist.includes(id)) {
            checkIfAlreadyExist.push(id);
            setCargando(true);
            const resumitSnapshot = await getOneResumit(id);
            setCargando(false);
            if (resumitSnapshot) {
              setDownloadedFilesComplete((before) => [...before, resumitSnapshot]);
            }
          }
        });
      } else {
        setCargando(false);
      }
    }
  };

  const conCarga = async () => {
    await getAndSetData();
  };

  useEffect(() => {
    conCarga();
  }, [currentUser]);

  return (
    <>
      {currentUser ? (
        <>
          {cargando ? (
            <Spinner />
          ) : (
            <Grid container spacing={3} p={3}>
              <Grid item xs={12}>
                <h1> Mis Resumits Descargados</h1>
              </Grid>
              {myDownloadedFilesComplete.length > 0 ? (
                <>
                  {myDownloadedFilesComplete.map((resumit) => (
                    <>
                      {resumit ? (
                        <Grid key={resumit.id} item xs={12} sm={6} md={3}>
                          <ResumitDownloadedCard product={resumit} />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <Grid item>
                  <h3>No has descargado resumenes aún ....</h3>
                </Grid>
              )}
            </Grid>
          )}
        </>
      ) : (
        <LoginFirst />
      )}
    </>
  );
};

export default MyResumitsDownloaded;
