import { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Stack, Card, Divider, Typography, CardHeader, Avatar, TextField, Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { setOptionSelected } from '../../store/optionSelectedForBuy/optionSelected.actions';
import Scrollbar from '../Scrollbar';

export default function BuyOptionsSelection({ title, subheader, list, isPremium, ...other }) {
  const [isOtherValue, setIsOtherValue] = useState(false);
  const [otherValue, setOtherValue] = useState(1000);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name === 'Otro Monto') {
      setIsOtherValue(true);
      dispatch(setOptionSelected(1000));
    } else {
      dispatch(setOptionSelected(e.target.value));
      setIsOtherValue(false);
    }
  };

  const handleChangeOtherValue = (value) => {
    dispatch(setOptionSelected(value));
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Scrollbar>
        <FormControl>
          <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
            <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
              {list.map((buyOption) => (
                <Stack key={buyOption.id} direction="row">
                  {buyOption.value === 'otro' ? (
                    <>
                      <FormControlLabel
                        value={1000}
                        control={<Radio />}
                        onClick={handleChange}
                        name={buyOption.title}
                        label={<BuyItemOption key={buyOption.id} buyOption={buyOption} />}
                      />
                      {isOtherValue ? (
                        <TextField
                          sx={{ paddingRight: 3 }}
                          value={otherValue}
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(event) => {
                            const { value } = event.target;
                            setOtherValue(value);
                            handleChangeOtherValue(value);
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <Tooltip
                      title={
                        isPremium && buyOption.value === 'nada'
                          ? 'Hubo mucho trabajo detras de este Resumit! Es Premium!'
                          : ''
                      }
                      arrow
                    >
                      <span>
                        <FormControlLabel
                          value={buyOption.price}
                          name={buyOption.title}
                          control={<Radio />}
                          onClick={handleChange}
                          label={<BuyItemOption key={buyOption.id} buyOption={buyOption} />}
                          disabled={isPremium && buyOption.value === 'nada'}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Stack>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Scrollbar>
      <Divider />
    </Card>
  );
}

function BuyItemOption({ buyOption }) {
  const { title, description, price, icon, color, id } = buyOption;

  return (
    <Stack direction="row" alignItems={'center'} spacing={2}>
      <Avatar sx={{ bgcolor: color }}>{icon}</Avatar>
      <Box sx={{ flexGrow: 1 }}>
        <Typography color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>
      {title !== 'Otro Monto' ? (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {price}$
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
}
