import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getDownloadURL } from 'firebase/storage';
import { useSelector } from 'react-redux';
// material
import { Box, Card, Link, Typography, Stack, Grid, Button, Badge, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import { getCoverFB, updateResumitImageUrl } from '../../../firebase/firebase.utils';
import Iconify from '../../../components/Iconify';
import { UserContext } from '../../../context/user.context';
import { selectUploadingResumit } from '../../../store/resumit/resumit.selector';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product, isInUploadingPage, chupalo, inSuccessPage }) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const {
    name,
    universidad,
    curso,
    coverPreview,
    status,
    id,
    userId,
    userName,
    downloadsCuantity,
    ratingTotal,
    ratings,
    isPremium,
  } = product;
  const [cover, setCover] = useState('');
  const navigate = useNavigate();
  const pruebaQlia = async () => {
    // if (!coverPreview){
    //   setCover("")
    // }
    if (product.coverPreviewUrl) {
      setCover(product.coverPreviewUrl);
    } else {
      const prueba = await getCoverFB(userId, id);
      prueba.items.forEach(async (itemRef) => {
        if (itemRef.name === id) {
          const url = await getDownloadURL(itemRef);
          setCover(url);
          updateResumitImageUrl(product, url);
        }
      });
    }
  };

  useEffect(() => {
    if (!isInUploadingPage) {
      pruebaQlia();
    }
  }, [userId]);

  return (
    <Badge
      badgeContent={
        isPremium && (
          <Box
            sx={{
              position: 'relative',
              display: 'inline-block',
              '&:hover .hover-text': {
                visibility: 'visible',
                opacity: 1,
              },
            }}
          >
            <Iconify icon="ic:baseline-star" sx={{ fontSize: '1.5rem' }} title="Premium" />
            <Box
              className="hover-text"
              sx={{
                visibility: 'hidden',
                opacity: 0,
                width: 'auto',
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                color: 'white',
                textAlign: 'center',
                borderRadius: '4px',
                padding: '2px 8px',
                position: 'absolute',
                zIndex: 1,
                top: '-25px',
                left: '50%',
                transform: 'translateX(-50%)',
                transition: 'opacity 0.3s',
              }}
            >
              Premium
            </Box>
          </Box>
        )
      }
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiBadge-badge': {
          top: 16,
          left: 20,
          color: 'gold',
        },
      }}
    >
      <Card>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {status && (
            <Label
              variant="filled"
              color={(status === 'sale' && 'error') || 'info'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {status}
            </Label>
          )}
          {isInUploadingPage ? (
            <ProductImgStyle alt={name} src={coverPreview} />
          ) : (
            <ProductImgStyle alt={name} src={cover} />
          )}
        </Box>
        <Stack
          direction={'row'}
          alignItems="center"
          paddingX={1.5}
          style={{ position: 'absolute', top: 5, right: 10, backgroundColor: '#faaf00', borderRadius: 20 }}
        >
          <Typography fontSize={16} fontWeight="bold" color={'white'}>
            {downloadsCuantity}
          </Typography>
          <Iconify icon="ic:baseline-file-download" color="white" fontSize="1.2rem" />
        </Stack>
        <Stack spacing={0.5} sx={{ p: 3 }}>
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography variant="h6" noWrap>
              {name.length > 20 ? `${name.substring(0, 20)}...` : name}
            </Typography>
          </Link>
          {isInUploadingPage && chupalo ? (
            <Typography variant="caption" noWrap display="block">
              {currentUser.displayName}
            </Typography>
          ) : (
            <Typography variant="caption" noWrap display="block">
              {userName}
            </Typography>
          )}
          <Typography variant="caption" noWrap display="block">
            {universidad}
          </Typography>
          <Typography variant="caption" noWrap display="block">
            {curso}
          </Typography>

          <Stack direction="row" paddingTop={1} alignItems="center">
            {isInUploadingPage || inSuccessPage ? (
              <></>
            ) : (
              <Button variant="contained" fullWidth onClick={() => navigate(`/resumits/${id}`)}>
                Ver Resumit
              </Button>
            )}
          </Stack>
          {ratingTotal ? (
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-around'} style={{ marginTop: 10 }}>
              <Rating value={ratingTotal} readOnly />
              <Typography fontSize={14} fontWeight="bold">
                {ratings.length}👤
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
      </Card>
    </Badge>
  );
}
