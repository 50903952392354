export const DefaultResumit = {
  name: 'Nombre del Resumit',
  curso: 'Curso',
  universidad: 'Universidad',
  price: '1000',
  displayName: 'Tu nombre',
  userName: 'Tu nombre',
  id: '',
  coverPreview: 'https://ayuda-psicologica-en-linea.com/wp-content/uploads/2020/06/haciendo-resumen.png',
};
