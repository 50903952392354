/* eslint-disable react/jsx-no-bind */
import { useState, useEffect } from 'react';
import * as React from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid';

import {
  storage,
  uploadFileAndSaveInUserFS,
  createResumitDocument,
  getOneResumit,
  getCoverFB,
  updateResumitDoc,
} from '../../firebase/firebase.utils';
import { universidades } from '../../assets/universidades';
import { cursos } from '../../assets/cursos';
import CopyToClipboardButton from '../copyToClipboar';
import { setUploadingResumit } from '../../store/resumit/resumit.action';
import { selectUploadingResumit } from '../../store/resumit/resumit.selector';
import { DefaultResumit } from '../../assets/defaultResumit';
// eslint-disable-next-line react/display-name
// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const UploadForm = ({ isEdit, id }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const uploadingResumit = useSelector(selectUploadingResumit);
  const [file, setFile] = useState('');
  const [cover, setCover] = useState('');
  const [coverPreview, setCoverPreview] = useState('si es esto me mato');
  const [resumit, setResumit] = useState({});
  const [isEnableButton, setEnableButton] = useState(true);
  const [open, setOpen] = useState(false);
  const [percent, setPercent] = useState(0);
  const [editResumit, setEditResumit] = useState({});
  const [nuevaUni, setNuevaUni] = useState(false);
  const [nuevoCurso, setNuevoCurso] = useState(false);
  const [editCoverDV, setEditCoverDV] = useState('');
  const [editFileDV, setEditFileDV] = useState('');
  const [isPremium, setIsPremium] = useState(false);

  const SetEditResumitFunc = async () => {
    const prueba = await getOneResumit(id);
    const copia = { ...prueba };

    const coverEdit = await getCoverFB(copia.userId, id);
    coverEdit.items.forEach(async (itemRef) => {
      if (itemRef.name === id) {
        const url = await getDownloadURL(itemRef);
        setCover(url);
        copia.coverPreview = url;
        setResumit(copia);
        setEditResumit(copia);
        dispatch(setUploadingResumit(copia));
        setIsPremium(copia.isPremium || false);
      }
    });
  };
  useEffect(() => {
    if (isEdit) {
      SetEditResumitFunc();
    } else dispatch(setUploadingResumit(DefaultResumit));
  }, []);

  const notifySuccess = (text) =>
    toast.success(text, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyFailure = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const handleClose = () => {
    setOpen(false);
    window.location.href = 'http://resumit.cl/myResumitsUploaded';
  };

  const resetForm = () => {};
  // check if an file is uploaded
  async function handleUpload(event) {
    event.preventDefault();
    resumit.isPremium = isPremium; // Add premium status
    if (!resumit.curso) {
      resumit.curso = 'Otro';
    }
    if (!resumit.universidad) {
      resumit.universidad = 'Otro';
    }
    if (!file && !isEdit) {
      // eslint-disable-next-line no-alert
      notifyFailure('Adjunta un archivo para subir tu Resumit!');
    } else if (file.size > 24000000) {
      notifyFailure('Maximo para el resumit son 24mb!');
    } else if (!resumit.name) {
      notifyFailure('Elige un nombre para tu Resumit!');
    } else if (!resumit.description) {
      notifyFailure('Agrega una descripción para tu Resumit!');
    } else if (!resumit.universidad) {
      notifyFailure('Elige una universidad para tu Resumit!');
    } else if (!resumit.curso) {
      notifyFailure('Elige un curso para tu Resumit!');
    } else if (!resumit.coverPreview && !isEdit) {
      notifyFailure('Elige una portada para tu Resumit!');
    } else if (cover.size > 2000000) {
      notifyFailure('Máximo para la portada son 2mb!');
    } else {
      setEnableButton(false);
      if (!isEdit) {
        const uniqueId = uuid();
        resumit.id = uniqueId;
        resumit.userId = currentUser.uid;
        resumit.userName = currentUser.displayName;
        resumit.email = currentUser.email;
      }
      // Subir al storage el file y el cover

      if (!isEdit) {
        const storageRefCover = ref(storage, `/files/${currentUser.uid}/cover/${resumit.id}`);
        const uploadTaskCover = await uploadBytesResumable(storageRefCover, cover);
        const storageRefFile = ref(storage, `/files/${currentUser.uid}/resumen/${resumit.id}`);
        const uploadTaskFile = uploadBytesResumable(storageRefFile, file);

        uploadTaskFile.on(
          'state_changed',
          (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

            // update progress
            setPercent(percent);
          },
          (err) => {
            console.log(err);
            setEnableButton(true);
            notifyFailure();
          },
          async () => {
            await createResumitDocument(resumit);
            await uploadFileAndSaveInUserFS(currentUser, resumit);
            notifySuccess('Resumit Subido! :)');
            setOpen(true);
            setEnableButton(true);
          }
        );
      }
      console.log(typeof cover);
      if (isEdit && typeof cover === 'string' && !file) {
        await updateResumitDoc(resumit.id, resumit);
        await uploadFileAndSaveInUserFS(currentUser, resumit);
        setOpen(true);
        notifySuccess('Resumit actualizado correctamente :)');
        setEnableButton(true);
      }
      if (isEdit && typeof cover === 'object' && !file) {
        console.log(typeof cover);
        const storageRefCover = ref(storage, `/files/${currentUser.uid}/cover/${resumit.id}`);
        const uploadTaskCover = uploadBytesResumable(storageRefCover, cover);

        uploadTaskCover.on(
          'state_changed',
          (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

            // update progress
            setPercent(percent);
          },
          (err) => {
            console.log(err);
            setEnableButton(true);
            notifyFailure();
          },
          async () => {
            await updateResumitDoc(resumit.id, resumit);
            await uploadFileAndSaveInUserFS(currentUser, resumit);
            setOpen(true);
            notifySuccess('Resumit actualizado correctamente :)');
            setEnableButton(true);
          }
        );
      } else if (isEdit && file) {
        const storageRefFile = ref(storage, `/files/${currentUser.uid}/resumen/${resumit.id}`);
        const uploadTaskFile = uploadBytesResumable(storageRefFile, file);

        uploadTaskFile.on(
          'state_changed',
          (snapshot) => {
            const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

            // update progress
            setPercent(percent);
          },
          (err) => {
            console.log(err);
            setEnableButton(true);
            notifyFailure();
          },
          async () => {
            await updateResumitDoc(resumit.id, resumit);
            await uploadFileAndSaveInUserFS(currentUser, resumit);
            setOpen(true);
            notifySuccess('Resumit actualizado correctamente :)');
            setEnableButton(true);
          }
        );
      }
    }
  }
  // Handles input change event and updates state
  function handleChangeFile(event) {
    setFile(event.target.files[0]);
  }

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    if (value === 'newOneUni') {
      setNuevaUni(true);
    } else if (value === 'newOneCurso') {
      setNuevoCurso(true);
    } else {
      const cambio = { ...uploadingResumit };
      cambio[name] = value;
      setResumit((values) => ({ ...values, [name]: value }));
      dispatch(setUploadingResumit(cambio));
    }
  };
  const handleChangeCover2 = (e) => {
    setCover(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        setCoverPreview(reader.result);
        const cambio = { ...uploadingResumit };
        cambio[name] = reader.result;
        dispatch(setUploadingResumit(cambio));
        setResumit((values) => ({ ...values, [name]: reader.result }));
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    const { name } = e.target;
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
      >
        <DialogTitle>{'Felicitaciones por subir tu Resumit!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Aqui tienes tu link para compartirlo por los grupos!
          </DialogContentText>
          <DialogContentText textAlign="center">
            <CopyToClipboardButton link={`http://resumit.cl/resumits/${resumit.id}`} text="Copiar Link" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      {currentUser ? (
        <span>
          <Grid container paddingTop={2} spacing={4}>
            <Grid key={isEdit ? editResumit.name : 'name'} item xs={12} md={12}>
              <TextField
                fullWidth
                type="text"
                name="name"
                required
                // defaultValue={isEdit ? editResumit.name : ''}
                defaultValue={isEdit ? editResumit.name : ''}
                onChange={handleChangeForm}
                label={'Nombre del Resumit'}
                placeholder="Resumit Interrogación Cálculo I"
              />
            </Grid>
            <Grid key={isEdit ? editResumit.description : 'des'} item xs={12} md={12}>
              <TextField
                fullWidth
                type="text"
                name="description"
                required
                multiline
                minRows={2}
                maxRows={12}
                defaultValue={isEdit ? editResumit.description : ''}
                onChange={handleChangeForm}
                label={'Descripción del Resumit'}
                placeholder="Este resumit contiene: 
                - Límites
                - Derivadas
                - Integrales
                Espero que les sirva :)"
              />
            </Grid>
            <Grid key={isEdit ? editResumit.universidad : 'uni'} item xs={12} md={6}>
              <TextField
                fullWidth
                required
                id="uni"
                // eslint-disable-next-line react/jsx-boolean-value
                select={!nuevaUni}
                name="universidad"
                label={!nuevaUni ? 'Escoge la universidad del resumit' : 'Nueva Universidad'}
                placeholder="Ej: Universidad ...."
                defaultValue={isEdit ? editResumit.universidad : ''}
                // value={currency}
                onChange={handleChangeForm}
                SelectProps={{
                  native: true,
                }}
              >
                {universidades.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
                <option key={'newOne'} value={'newOneUni'}>
                  Agregar otra Universidad
                </option>
              </TextField>
            </Grid>{' '}
            <Grid key={isEdit ? editResumit.curso : 'curso'} item xs={12} md={6}>
              <TextField
                fullWidth
                required
                id="curso"
                select={!nuevoCurso}
                placeholder={!nuevoCurso ? '' : 'Ej: Electricidad y Magnetismo'}
                name="curso"
                label={!nuevaUni ? 'Escoge el curso del resumit' : 'Nuevo Curso'}
                defaultValue={isEdit ? editResumit.curso : ''}
                onChange={handleChangeForm}
                SelectProps={{
                  native: true,
                }}
              >
                {cursos.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
                <option key={'newOne'} value={'newOneCurso'}>
                  Agregar otro curso
                </option>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography paddingLeft={2} variant="h6">
                {' '}
                Adjunte Portada de su Resumit
              </Typography>
              <TextField
                fullWidth
                type="file"
                inputProps={{ accept: 'image/*' }}
                name="coverPreview"
                required
                onChange={handleChangeCover2}
              />{' '}
            </Grid>{' '}
            <Grid item xs={12} md={6}>
              <Typography paddingLeft={2} variant="h6">
                {' '}
                Adjunte su Resumit
              </Typography>
              <TextField fullWidth type="file" name="file" required onChange={handleChangeFile} />{' '}
            </Grid>{' '}
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPremium}
                    onChange={(e) => setIsPremium(e.target.checked)}
                    name="isPremium"
                    color="primary"
                  />
                }
                label="Marcar como Resumit Premium"
              />
            </Grid>
            <Grid item xs={12}>
              {isEnableButton ? (
                <Button fullWidth variant="contained" onClick={handleUpload}>
                  {isEdit ? 'Actualizar' : 'Subir'} Resumit
                </Button>
              ) : (
                <Button fullWidth disabled variant="contained">
                  {isEdit ? 'Actualizar' : 'Subir'} Resumit
                </Button>
              )}

              <Typography variant="h4" align="center" sx={{ px: 1, mt: 1, mb: 1 }}>
                {percent}% done
              </Typography>
            </Grid>{' '}
          </Grid>
        </span>
      ) : (
        <h2>Sign in for upload files</h2>
      )}
    </div>
  );
};
export default UploadForm;
