import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getDownloadURL } from 'firebase/storage';
// material
import { Box, Card, Link, Typography, Stack, Grid, Button, Rating, Badge } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { yellow } from '@mui/material/colors';
// utils
// components
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import {
  getCoverFB,
  downloadedFileAndSaveInUserFS,
  updateResumitImageUrl,
  getFileFB,
} from '../../../firebase/firebase.utils';
import Iconify from '../../../components/Iconify';

import './Resumit.css';
// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const labels = {
  1: 'Como la tula',
  2: 'La wea mala',
  3: 'Piola tu wea',
  4: 'Like it',
  5: 'Me salvaste el semestre',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
// ----------------------------------------------------------------------

ResumitDownloadedCard.propTypes = {
  product: PropTypes.object,
};

export default function ResumitDownloadedCard({ product, isInUploadingPage }) {
  const { name, universidad, curso, coverPreview, price, status, id, userId } = product;
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const [cover, setCover] = useState('');
  const [file, setFile] = useState('');
  const navigate = useNavigate();

  const pruebaQlia = async () => {
    if (product.coverPreviewUrl) {
      setCover(product.coverPreviewUrl);
      console.log('product.coverPreviewUrl', product.coverPreviewUrl);
    } else {
      const prueba = await getCoverFB(userId, id);
      prueba.items.forEach(async (itemRef) => {
        if (itemRef.name === id) {
          const url = await getDownloadURL(itemRef);

          setCover(url);
          updateResumitImageUrl(product, url);
        }
      });
    }
  };

  const handleDownload = async () => {
    const files = await getFileFB(userId, id);
    files.items.forEach(async (itemRef) => {
      if (itemRef.name === id) {
        const url = await getDownloadURL(itemRef);
        console.log('url', url);
        setFile(url);
      }
    });
  };

  useEffect(() => {
    if (!isInUploadingPage) {
      pruebaQlia();
      handleDownload();
    }
  }, [userId]);

  return (
    // <Badge
    //   color="warning"
    //   className="chupala"
    //   badgeContent="3⭐️"
    //   anchorOrigin={{
    //     vertical: 'top',
    //     horizontal: 'right',
    //   }}
    // >
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        {isInUploadingPage ? (
          <ProductImgStyle alt={name} src={coverPreview} />
        ) : (
          // <ProductImgStyle alt={name} src={cover} />
          <ProductImgStyle alt={name} src={cover} />
        )}
        {/* <ProductImgStyle alt={name} src="https://d1ajrs9fiy78eo.cloudfront.net/2398a8f0e17604ea5934dcd8403b433c.png" /> */}
      </Box>

      <Stack spacing={0.5} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="h6" noWrap>
            {name}
          </Typography>
        </Link>
        <Typography variant="caption" noWrap display="block">
          {universidad}
        </Typography>
        <Typography variant="caption" noWrap display="block">
          {curso}
        </Typography>

        <Stack direction="row" paddingTop={1} alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}

          <a href={file} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
            <Button variant="contained">Descargar Resumit</Button>
          </a>
          {/* <Typography component="legend">Controlled</Typography> */}

          {/* &nbsp; */}
          {/* {fCurrency(price)} */}
        </Stack>
        {/* <Stack alignItems={'center'}>
          <Rating
            fullWidth
            getLabelText={getLabelText}
            //   style={{ paddingLeft: 30 }}
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
        </Stack> */}
      </Stack>
    </Card>
    // </Badge>
  );
}
