import { useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { getDownloadURL } from 'firebase/storage';
import ShopProductCard from '../../sections/@dashboard/products/ProductCard';
import { DefaultResumit } from '../../assets/defaultResumit';
import { setActionInUserDoc, getDataOfUser, getOneResumit, getFileFB } from '../../firebase/firebase.utils';
import { UserContext } from '../../context/user.context';
import Spinner from '../../components/spinner/spinner';
import LastDowloadsCard from '../../components/lastDownloadsCard';

const SuccessPaymentPage = () => {
  const [resumit, setResumit] = useState(DefaultResumit);
  const [precioCompra, setPrecioCompra] = useState(0);
  const [file, setFile] = useState('');
  const currentUser = useSelector((state) => state.user.currentUser);
  const [cargando, setCargando] = useState(true);

  const setData = async () => {
    const dataOfUser = await getDataOfUser(currentUser);
    const { compraID, precioCompra } = await dataOfUser.data().action;
    setCargando(true);
    const resumitLocal = await getOneResumit(compraID);
    setCargando(false);
    console.log(resumitLocal);
    const { userId, id } = resumitLocal;
    setResumit(resumitLocal);
    setPrecioCompra(precioCompra);
    setActionInUserDoc(currentUser, compraID, 'checkout', 'success', precioCompra);
    const prueba = await getFileFB(userId, id);
    prueba.items.forEach(async (itemRef) => {
      if (itemRef.name === id) {
        const url = await getDownloadURL(itemRef);
        setFile(url);
      }
    });
  };
  useEffect(() => {
    if (currentUser) {
      setData();
    }
  }, [currentUser]);

  return (
    <>
      {cargando ? (
        <Spinner />
      ) : (
        <Grid spacing={3} container>
          <Grid item xs={12}>
            <LastDowloadsCard />
          </Grid>
          <Grid item xs={12} paddingBottom={3}>
            {parseInt(precioCompra, 10) === 0 ? (
              <Typography align="center" variant="h3">
                Pense que al menos me invitarías un cafe :(
              </Typography>
            ) : (
              <Typography align="center" variant="h3">
                Muuchas gracias! Espero que te sirva el Resumit! :)
              </Typography>
            )}
          </Grid>
          <Grid item paddingTop={4} xs={12} md={3}>
            <ShopProductCard product={resumit} inSuccessPage />
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <Grid item>
                <Stack spacing={3} paddingLeft={4} paddingTop={3} justifyContent="space-around" direction="row">
                  <Typography variant="h5">Resumit Comprado:</Typography>
                  <Typography variant="h5" color="primary">
                    {resumit.name}
                  </Typography>
                </Stack>
                <Stack spacing={3} paddingLeft={4} paddingTop={3} justifyContent="space-around" direction="row">
                  <Typography variant="h5" paddingRight={17}>
                    Cantidad Aportada:
                  </Typography>
                  <Typography variant="h5" color="primary">
                    {precioCompra}$
                  </Typography>
                </Stack>

                <Typography paddingTop={3} align="center" variant="body1">
                  Si tu descarga no comenzo automaticamente, descargalo aqui abajo!
                </Typography>
                <Grid textAlign="center" item paddingTop={3} paddingBottom={3} xs={12}>
                  <a href={file} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                    <Button variant="contained">Descargar Resumit</Button>
                  </a>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SuccessPaymentPage;
