import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import env from 'react-dotenv';
import { Button, Grid } from '@mui/material';
import CoffeeIcon from '@mui/icons-material/Coffee';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import WineBarIcon from '@mui/icons-material/WineBar';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { ToastContainer, toast } from 'react-toastify';
import { red, blue, brown, grey, green } from '@mui/material/colors';
import { getDownloadURL } from 'firebase/storage';
import BuyOptionsSelection from './SelectOption';
import { getFileFB, setActionInUserDoc } from '../../firebase/firebase.utils';
import AuthSocial from '../../sections/auth/AuthSocial';
import { selectOptionForBuy } from '../../store/optionSelectedForBuy/optionSelected.selector';

const OpcionesCompras = [
  {
    value: 'metro',
    title: 'Un Pasaje de Micro',
    icon: <DirectionsTransitIcon />,
    price: 700,
    color: blue[500],
    id: 3,
  },
  {
    value: 'cafe',
    title: 'Un Café',
    icon: <CoffeeIcon />,
    price: 1500,
    color: brown[500],
    id: 1,
  },
  {
    value: 'piscola',
    title: 'Una Piscola',
    icon: <WineBarIcon />,
    price: 3000,
    color: red[500],
    id: 2,
  },
  {
    value: 'otro',
    title: 'Otro Monto',
    icon: <SentimentVerySatisfiedIcon />,
    price: 5000,
    color: green[500],
    id: 5,
  },
  {
    value: 'nada',
    title: 'Nada... (Tu Resumiter lo sabrá :)',
    icon: <HeartBrokenIcon />,
    price: 0,
    color: grey[400],
    id: 4,
  },
];

const BuyForm = ({ product }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const optionSelectedForBuy = useSelector(selectOptionForBuy);
  const { id, userId, name, isPremium } = product;
  const [file, setFile] = useState('');
  const [isClickButton, setClickButton] = useState(false);

  const pruebaQlia = async () => {
    const prueba = await getFileFB(userId, id);
    prueba.items.forEach(async (itemRef) => {
      if (itemRef.name === id) {
        const url = await getDownloadURL(itemRef);
        setFile(url);
      }
    });
  };

  const makeAPICall = async () => {
    if (parseInt(optionSelectedForBuy, 10) === 0 && !isPremium) {
      console.log(product.userName);
      try {
        const response = await axios.post('https://resumit-server-node.herokuapp.com/mailnotpayment', {
          title: name,
          unit_price: parseInt(optionSelectedForBuy, 10),
          uid: currentUser.uid,
          email: currentUser.email,
          email_vendedor: product.email,
          name_comprador: currentUser.displayName,
          resumit_id: product.id,
          resumit_user_id: product.userId,
          resumit_user_name: product.userName,
        });
        window.location.href = 'http://resumit.cl/payment/success';
      } catch (e) {
        console.log(e);
      }
    } else if (parseInt(optionSelectedForBuy, 10) < 700) {
      notifyFailure('El mínimo valor para depositar es $700');
      setClickButton(false);
    } else {
      try {
        const response = await axios.post('https://resumit-server-node.herokuapp.com/payment', {
          title: name,
          unit_price: parseInt(optionSelectedForBuy, 10),
          uid: currentUser.uid,
          email: currentUser.email,
          email_vendedor: product.email,
          name_comprador: currentUser.displayName,
          resumit_id: product.id,
          resumit_user_id: product.userId,
          resumit_user_name: product.userName,
        });
        window.location.href = response.data.init_point;
      } catch (e) {
        console.log(e);
      }
    }
  };

  const notifyFailure = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const handleDownload = async () => {
    setClickButton(true);
    await setActionInUserDoc(currentUser, product.id, 'checkout', 'pending', optionSelectedForBuy);
    makeAPICall();
  };

  useEffect(() => {
    pruebaQlia();
  }, [userId]);

  return (
    <Grid container spacing={3}>
      <ToastContainer />
      <Grid item xs={12}>
        <BuyOptionsSelection title="Invitale a tu Resumiter 🙌" list={OpcionesCompras} isPremium={isPremium} />
      </Grid>
      {currentUser ? (
        <Grid item xs={12} paddingTop={2}>
          {optionSelectedForBuy && !isClickButton ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  handleDownload();
                }}
              >
                Descarga y Apoya a tu Resumiter!
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Button variant="contained" disabled fullWidth>
                Descarga y Apoya a tu Resumiter!
              </Button>
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} paddingLeft={3} paddingTop={2}>
          <AuthSocial />
        </Grid>
      )}
    </Grid>
  );
};

export default BuyForm;
