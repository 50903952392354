/* eslint-disable object-shorthand */
import { hi } from 'date-fns/locale';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  collection,
  updateDoc,
  deleteField,
  deleteDoc,
  orderBy,
  where,
} from 'firebase/firestore';
import { getStorage, listAll, list, getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { get } from 'lodash';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDwCUwU_cXkU_iefEJ9MuDmfKL6egh7Wzs',
  authDomain: 'resumit-web-app.firebaseapp.com',
  projectId: 'resumit-web-app',
  storageBucket: 'resumit-web-app.appspot.com',
  messagingSenderId: '540887151677',
  appId: '1:540887151677:web:ff3d2cb225651e7652e654',
  measurementId: 'G-Z775T8EG0B',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();
// eslint-disable-next-line jest/require-hook
googleProvider.setCustomParameters({
  prompt: 'select_account',
});

export const storage = getStorage(firebaseApp);
export const auth = getAuth();
const analytics = getAnalytics(firebaseApp);
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
export const db = getFirestore();

export const createResumitDocument = async (resumit) => {
  // Crear documento del resumit, una vez q se sube el documento y guardarlo en la coleccion de resumit
  resumit.downloadsCuantity = 0;
  resumit.downloadsData = [];
  resumit.dineroGenerado = 0;
  resumit.comments = [];
  const {
    // coverPreview,
    curso,
    id,
    name,
    universidad,
    userId,
    userName,
    downloadsCuantity,
    downloadsData,
    comments,
    dineroGenerado,
    description,
    email,
    isPremium,
  } = resumit;
  const resumitDocRef = doc(db, 'resumit', resumit.id);
  try {
    await setDoc(resumitDocRef, {
      // coverPreview,
      curso,
      downloadsCuantity,
      downloadsData,
      id,
      name,
      universidad,
      userId,
      userName,
      dineroGenerado,
      comments,
      description,
      email,
      isPremium,
    });
  } catch (error) {
    console.log('error creating resumit', error);
  }
};

export const updateResumitDoc = async (resumitId, updateData) => {
  const resumitDocRef = doc(db, 'resumit', resumitId);
  try {
    await updateDoc(resumitDocRef, updateData);
  } catch (error) {
    console.log('error updating resumit', error);
  }
};

export const deleteResumitDocAndFilesInStorage = async (userAuthId, resumitID) => {
  // Elimina el resumit doc en el fb ademas de eliminar el cover y el archivo, tambien elimina my uploaded data

  // Eliminar de myUploadedData, primero lo pido, y borro ese id
  const userDocRef = doc(db, 'users', userAuthId);
  const userSnapshot = await getDoc(userDocRef);
  // eslint-disable-next-line prefer-const
  let { myUploadedFiles } = userSnapshot.data();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < myUploadedFiles.length; i++) {
    if (myUploadedFiles[i] === resumitID) {
      myUploadedFiles.splice(i, 1);
    }
  }
  await updateDoc(userDocRef, {
    myUploadedFiles: myUploadedFiles,
  });

  // Eliminar de myResumitDoc
  await deleteDoc(doc(db, 'resumit', resumitID));

  // Todo: Eliminar del storage
  const resumitCoverRef = ref(storage, `files/${userAuthId}/cover/${resumitID}`);
  const resumitResumenRef = ref(storage, `files/${userAuthId}/resumen/${resumitID}`);
  deleteObject(resumitCoverRef)
    .then(() => {
      // File deleted successfully
      console.log('Cover deleted bro');
      console.log(resumitID);
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      console.log('Fak, problem deleting Cover deleted bro');
    });
  deleteObject(resumitResumenRef)
    .then(() => {
      // File deleted successfully
      console.log('Resumen deleted bro');
    })
    .catch((error) => {
      console.log('Fak, problem deleting Resumen deleted bro');
      // Uh-oh, an error occurred!
    });
};

export const setActionInUserDoc = async (userAuth, resumitId, userAction, status, precioCompra) => {
  // guardar accion en el userID del comprador
  const userDocRef = doc(db, 'users', userAuth.uid);
  let action = {};
  // const userSnapshot = await getDoc(userDocRef);
  // eslint-disable-next-line prefer-const
  if (userAction === 'checkout') {
    action = {
      compraID: resumitId,
      precioCompra: precioCompra,
      status: status,
      userAction: userAction,
    };
  } else if (userAction === 'viendo') {
    action = {
      compraID: '',
      precioCompra: '',
      status: '',
      userAction: 'viendo',
    };
  }

  try {
    await updateDoc(userDocRef, {
      action: action,
    });
  } catch (error) {
    console.log('error updating action of user', error.mesagge);
  }
  return userDocRef;
};

export const setDatosBancariosInUserDoc = async (userAuth, datosBancarios) => {
  // guardar datos bancarios en el userDoc
  const userDocRef = doc(db, 'users', userAuth.uid);
  const { nombreApellido, rut, email, banco, tipoCuenta, numeroCuenta } = datosBancarios;

  try {
    await updateDoc(userDocRef, {
      datosBancarios: datosBancarios,
    });
  } catch (error) {
    console.log('error updating Datos Bancarios of user', error.mesagge);
  }
  return userDocRef;
};

export const uploadFileAndSaveInUserFS = async (userAuth, resumit) => {
  // guardar en el userDocument, en myUploadedFiles el id del resumit subido

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  // eslint-disable-next-line prefer-const
  let { myUploadedFiles } = userSnapshot.data();
  if (!myUploadedFiles.includes(resumit.id)) {
    myUploadedFiles.push(resumit.id);
  }

  try {
    await updateDoc(userDocRef, {
      myUploadedFiles,
    });
  } catch (error) {
    console.log('error creating user', error.mesagge);
  }
  return userDocRef;
};

export const downloadedFileAndSaveInUserFS = async (userAuth, resumitDownloaded) => {
  // Cuando un Usuario descarga un archivo, se guarda en myDownloadedFiles y en el documento del resumit, agregar su descargar y agregarle 1 sus downloades
  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  // eslint-disable-next-line prefer-const
  let { myDownloadedFiles } = userSnapshot.data();

  myDownloadedFiles.push(resumitDownloaded.id);

  try {
    await updateDoc(userDocRef, {
      myDownloadedFiles,
    });
  } catch (error) {
    console.log('error creating user', error.mesagge);
  }
  return userDocRef;
};

export const commentInResumit = async (resumitComment, comment) => {
  // Cuando se comenta un resumit, ir a agregarle a resumit.comments la data del comentario
  const { typeOfComment, textComment, rating, nameOfComment, dayOfComment } = comment;
  const resumitDocRef = doc(db, 'resumit', resumitComment.id);
  const resumitSnapshot = await getDoc(resumitDocRef);

  // eslint-disable-next-line no-unused-vars, prefer-const
  let { comments } = resumitSnapshot.data();
  if (typeOfComment === 'Autor') {
    comments.unshift({ typeOfComment, textComment, rating, nameOfComment, dayOfComment });
  } else {
    comments.push({ typeOfComment, textComment, rating, nameOfComment, dayOfComment });
  }
  try {
    await updateDoc(resumitDocRef, {
      comments: comments,
    });
  } catch (error) {
    console.log('error creating comment weas', error.mesagge);
  }
  return resumitDocRef;
};

export const setDineroPorRetirar = async (userAuth, dineroASetear) => {
  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  let { dineroRetirado, dineroPorRetirar } = userSnapshot.data();
  dineroRetirado += dineroPorRetirar;
  dineroPorRetirar = dineroASetear;
  try {
    await updateDoc(userDocRef, {
      dineroPorRetirar: dineroPorRetirar,
      dineroRetirado: dineroRetirado,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
};

export const downloadedFileAndSaveInResumitFS = async (userAuth, resumitDownloaded, precioComprado) => {
  // Cuando un Usuario descarga un archivo, use se guarda en el documento del resumit, agregar su descarga y agregarle 1 sus downloades
  const resumitDocRef = doc(db, 'resumit', resumitDownloaded.id);
  const resumitSnapshot = await getDoc(resumitDocRef);
  // Tambien agregar el precio comprado al dueño del resumit
  const userDocRef = doc(db, 'users', resumitDownloaded.userId);
  const userSnapshot = await getDoc(userDocRef);
  // eslint-disable-next-line no-unused-vars, prefer-const
  let { downloadsCuantity, downloadsData, dineroGenerado } = resumitSnapshot.data();
  let { dineroPorRetirar } = userSnapshot.data();
  const mailComprador = userAuth.email;
  const dayOfPurchase = new Date();
  downloadsCuantity += 1;
  dineroPorRetirar += parseInt(precioComprado, 10);
  dineroGenerado += parseInt(precioComprado, 10);
  downloadsData.push({ precioComprado, mailComprador, dayOfPurchase });
  try {
    await updateDoc(resumitDocRef, {
      downloadsCuantity: downloadsCuantity,
      downloadsData: downloadsData,
      dineroGenerado: dineroGenerado,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
  try {
    await updateDoc(userDocRef, {
      dineroPorRetirar: dineroPorRetirar,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
  return resumitDocRef;
};

export const updateResumitImageUrl = async (resumit, url) => {
  const resumitDocRef = doc(db, 'resumit', resumit.id);
  try {
    await updateDoc(resumitDocRef, {
      coverPreviewUrl: url,
    });
  } catch (err) {
    console.log('error updating resumit weas', err.mesagge);
  }
};

export const getAllResumits = async () => {
  // Obtener todos los resumits de la base de datos ordenados por cantidad de descargas de manera descendiente
  const resumitCollection = collection(db, 'resumit');
  const q = query(resumitCollection, orderBy('downloadsCuantity', 'desc'));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
};
export const getOneResumit = async (idResumit) => {
  // Obtener los datos de un resumit pedido para su page personal

  const docRef = doc(db, 'resumit', idResumit);
  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

export const createUserDocumentFromAuth = async (userAuth) => {
  // Crea documento del User la primera vez que inicia sesión ya con el array creado de sus archivos subidos y sus descargas de otros archivos

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  const myUploadedFiles = [];
  const myDownloadedFiles = [];
  const dineroPorRetirar = 0;
  const dineroRetirado = 0;
  const action = {};

  if (userSnapshot.exists() === false) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        dineroPorRetirar,
        dineroRetirado,
        myUploadedFiles,
        myDownloadedFiles,
        action,
      });
    } catch (error) {
      console.log('error creating user', error.mesagge);
    }
  }
  return userDocRef;
};

export const getDataOfUser = async (user) => {
  let userDocRef;
  if (user.uid === undefined) {
    userDocRef = doc(db, 'users', user);
  } else {
    userDocRef = doc(db, 'users', user.uid);
  }
  const userSnapshot = await getDoc(userDocRef);
  return userSnapshot;
};

export const getHistorial = async () => {
  const historialTelas = [];
  const historialAgujas = [];
  const q = query(collection(db, 'historial'), orderBy('date', 'desc'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    if (doc.data().invitado) {
      const obj = doc.data();
      obj.idHistorial = doc.id;
      historialTelas.push(obj);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (doc.data().nameComprador !== 'Nicolas Pirozzi') {
        const obj = doc.data();
        obj.idHistorial = doc.id;
        historialAgujas.push(obj);
      }
    }
  });
  console.log(historialTelas);
  console.log(historialAgujas);
  const historial = [
    historialTelas[0],
    historialAgujas[0],
    historialTelas[1],
    historialAgujas[1],
    historialTelas[2],
    historialAgujas[2],
    historialTelas[3],
    historialAgujas[3],
    historialTelas[4],
    historialAgujas[4],
  ];
  return historial;
};

export const getCoverFB = async (userUid) => {
  const storageRefCover = ref(storage, `files/${userUid}/cover`);
  const todosImagenes = await list(storageRefCover);
  return todosImagenes;
};
export const getFileFB = async (userUid) => {
  const storageRefCover = ref(storage, `files/${userUid}/resumen`);
  const todosFiles = await list(storageRefCover);
  return todosFiles;
};

export const getProfilePic = async (userUid) => {
  const storageRefCover = ref(storage, `files/${userUid}/profilePic`);
  const url = await getDownloadURL(storageRefCover);
  console.log(url);
  return url;
};

export const getIdForPopUp = async (user) => {
  const userSnapshot = await getDataOfUser(user);
  const userDocRef = doc(db, 'users', user.uid);
  const date1 = new Date();
  const { myDownloadedFiles } = userSnapshot.data();
  const index = myDownloadedFiles.findIndex(({ mostradoPopUp }) => mostradoPopUp === false);
  let resumitForPopUp;
  if (index !== -1) {
    resumitForPopUp = myDownloadedFiles[index];
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const countDaysFromDownload = Math.ceil(Math.abs(date1 - resumitForPopUp.downloadedAt.toDate()) / MS_PER_DAY) - 1;
    if (countDaysFromDownload > 0) {
      resumitForPopUp.mostradoPopUp = true;
      myDownloadedFiles[index] = resumitForPopUp;
      console.log(myDownloadedFiles);
      try {
        await updateDoc(userDocRef, {
          myDownloadedFiles: myDownloadedFiles,
        });
      } catch (error) {
        console.log('error creating resumit weas', error.mesagge);
      }
      return resumitForPopUp.resumitDownloadedId;
    }
  }
  return undefined;
};

export const setRatingInResumit = async (idResumit, nota, idUser) => {
  // AGregar al resumit ref el rating del usuario y actualizar el rating total
  const resumitDocRef = doc(db, 'resumit', idResumit);
  const querySnapshot = await getDoc(resumitDocRef);
  let { ratings } = querySnapshot.data();
  let { ratingTotal } = querySnapshot.data();
  if (!ratings) {
    ratings = [];
  }
  if (!ratingTotal) {
    ratingTotal = 5;
  }
  // Comprobar si ya le coloco rating a ese resumit
  let indexOfRating;
  if (ratings.length >= 1) {
    indexOfRating = querySnapshot.data().ratings.findIndex(({ userUid }) => userUid === idUser);
  }
  console.log(indexOfRating);
  if (indexOfRating !== -1 && indexOfRating !== undefined) {
    console.log('chua');
    ratings[indexOfRating] = { nota, userUid: idUser };
    let promedio = 0;
    ratings.forEach((rating) => {
      promedio += rating.nota;
    });
    promedio /= ratings.length;
    ratingTotal = promedio;
  } else {
    console.log('aqui');
    ratingTotal = (ratingTotal * ratings.length + nota) / (ratings.length + 1);
    ratings.push({ nota, userUid: idUser });
  }
  try {
    await updateDoc(resumitDocRef, {
      ratingTotal: ratingTotal,
      ratings: ratings,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
};

export const setProfilePicIdInUserFB = async (userAuth) => {
  const userDocRef = doc(db, 'users', userAuth.uid);
  try {
    await updateDoc(userDocRef, {
      hasProfilePic: true,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
};
export const setTextDescriptionInUserFB = async (userAuth, text) => {
  const userDocRef = doc(db, 'users', userAuth.uid);
  try {
    await updateDoc(userDocRef, {
      profileDescription: text,
    });
  } catch (error) {
    console.log('error creating resumit weas', error.mesagge);
  }
};

export const getLikesAndDislikesInReactions = async (idResumit) => {
  const resumitDocRef = doc(db, 'resumit', idResumit);
  const querySnapshot = await getDoc(resumitDocRef);
  const { likes, dislikes } = querySnapshot.data();
  return { likes, dislikes };
};

export const addLikesAndDislikesInReactions = async (idHistorial, type) => {
  console.log(idHistorial, type);
  const historialDocRef = doc(db, 'historial', idHistorial);
  const querySnapshot = await getDoc(historialDocRef);
  let { likes, dislikes } = querySnapshot.data();
  if (!likes) {
    likes = 0;
  }
  if (!dislikes) {
    dislikes = 0;
  }
  if (type === 'like') {
    likes += 1;
  } else {
    dislikes += 1;
  }
  try {
    await updateDoc(historialDocRef, {
      likes: likes,
      dislikes: dislikes,
    });
    console.log('se actualizo likes y dislikes');
  } catch (error) {
    console.log('error updating likes and dislikes', error.mesagge);
  }
};

// eslint-disable-next-line no-return-await
export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);
