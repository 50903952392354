export const cursos = [
  'Otro',
  'Álgebra',
  'Algebra Lineal',
  'Análisis Económico',
  'Aplicaciones Matemáticas',
  'Biología de la Célula',
  'Bilogía de organismos y comunidades',
  'Cálculo I',
  'Cálculo II',
  'Cálculo III',
  'Central de Apuntes Antigua',
  'Competencia y Mercado',
  'Comportamiento Humano',
  'Comportamiento Organizacional',
  'Contabilidad Gerencial',
  'Contabilidad I',
  'Contabilidad II',
  'Contabilidad y Toma de Decisiones',
  'Creación de Nuevas Empresas',
  'Desafíos de la Ingeniería',
  'Dinámica',
  'Dirección de Empresas',
  'Econometría',
  'Economía Matemática',
  'Economía y Filosofía',
  'Ecuaciones diferenciales',
  'Electricidad y Magnetismo',
  'Empresa y Legislación',
  'Estrategia de la Organización',
  'Ética para ingenieros',
  'Examen de Grado Administración',
  'Examen de Grado Economía',
  'Filosofía: ¿Para qué?',
  'Finanzas I',
  'Finanzas II',
  'Fundamentos de Dirección de Empresas',
  'Fundamentos de Finanzas',
  'Fundamentos de Marketing',
  'Horizontes y Desafíos en la Gestión de Empresas',
  'Inferencia Estadística',
  'Ingeniería de Software',
  'Intro a la Macroeconomía',
  'Intro a la Microeconomía',
  'Intro a la Programación',
  'Intro. a la Economía ',
  'Introduccion Algebra Lineal',
  'Laboratorios',
  'Macroeconomía I',
  'Macroeconomía II',
  'Macroeconomía Internacional',
  'Matemáticas, Discretas',
  'Mercados I',
  'Mercados II',
  'Métodos de Optimización',
  'Microeconomía I',
  'Microeconomía II',
  'OFG',
  'OPR',
  'Optimización',
  'PIMU',
  'Probabilidad y Estadística',
  'Química para ingenieros',
  'RR.HH',
  'Talleres de Computación',
  'Teoría Econométrica I',
  'Teoría Financiera',
  'Teoría Macroeconómica I',
  'Teoría Microeconómica I',
  'Termodinamica',
];
