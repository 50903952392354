import { Grid, Stack, Button, Typography, Card, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { getOneResumit } from '../firebase/firebase.utils';
import ResumitFullCard from '../sections/@dashboard/products/ResumitFullCard';
import BuyForm from '../components/buyForm/BuyForm.component';
import CommentsBox from '../components/commentsBox/commentsBox';
import Spinner from '../components/spinner/spinner';
import LastDowloadsCard from '../components/lastDownloadsCard';

const ResumitFullPage = () => {
  const [resumit, setResumit] = useState('');
  const [cargando, setCargando] = useState(true);
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const PruebaQlia = async () => {
    const prueba = await getOneResumit(id);
    setCargando(false);
    setResumit(prueba);
  };
  useEffect(() => {
    PruebaQlia();
  }, []);

  return (
    <>
      {cargando ? (
        <Spinner />
      ) : (
        <Grid container spacing={5} p={0}>
          <Grid item xs={12}>
            <LastDowloadsCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResumitFullCard product={resumit} />
          </Grid>
          <Grid item xs={12} md={5}>
            <BuyForm product={resumit} />
          </Grid>
          <Grid item xs={12} mx={2}>
            <CommentsBox product={resumit} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ResumitFullPage;
