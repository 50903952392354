import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHistorial } from '../store/historialFB/historialFB.selector';
import { addLikesAndDislikesInReactions } from '../firebase/firebase.utils';

const { Card, CardHeader, Typography, Divider, Stack, Button, Grid, Badge } = require('@mui/material');

const getProductWithThePrice = (price) => {
  if (price === 700) {
    return 'PASAJE DE MICRO';
  }
  if (price <= 1500) {
    return 'CAFÉ';
  }
  if (price <= 3000) {
    return 'PISCOLA';
  }
  return 'PISCOLA';
};

const getRandomNumber = () => {
  return Math.round(Math.random() * 10);
};

const LastDowloadsCard = () => {
  const historial = useSelector(selectHistorial);
  console.log(historial);

  return (
    <Card style={{ padding: 10 }}>
      <CardHeader title="Últimas descargas" />
      <Grid m={3} maxHeight={180} overflow="auto" container>
        {historial ? (
          <>
            {historial.map((item) => (
              <TextDisplay
                key={item.date}
                nameComprador={item.nameComprador}
                nameResumiter={item.nameResumiter}
                invitado={item.invitado ? getProductWithThePrice(item.invitado) : false}
                likes={item.likes}
                dislikes={item.dislikes}
                idHistorial={item.idHistorial}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Card>
  );
};

const TextDisplay = ({ nameComprador, invitado, nameResumiter, likes, dislikes, idHistorial }) => {
  if (invitado && likes === 0) {
    likes += 1;
  } else if (!invitado && dislikes === 0) {
    dislikes += 1;
  }
  const [likesAndDislikes, setLikesAndDislikes] = useState({ likes, dislikes });

  const handleLike = () => {
    setLikesAndDislikes({ ...likesAndDislikes, likes: likesAndDislikes.likes + 1 });
    addLikesAndDislikesInReactions(idHistorial, 'like');
  };
  const handleDislike = () => {
    setLikesAndDislikes({ ...likesAndDislikes, dislikes: likesAndDislikes.dislikes + 1 });
    addLikesAndDislikesInReactions(idHistorial, 'dislike');
  };
  return (
    <Grid container mr={4}>
      <Grid container xs={10} spacing={0.6} alignItems="center">
        <Grid item>
          <Typography fontWeight={'bold'} fontSize={15} color={invitado ? 'green' : 'red'}>
            {nameComprador}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{invitado ? 'ha invitado un' : 'no ha invitado'}</Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={'bold'} fontSize={15} color={invitado ? 'green' : 'red'}>
            {invitado || 'nada'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">a</Typography>
        </Grid>
        <Grid item>
          <Typography fontWeight={'bold'} fontSize={15} color={'blueviolet'}>
            {nameResumiter}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">por su resumit!</Typography>
        </Grid>
      </Grid>
      <Grid container xs={2}>
        <Grid item>
          <Button style={{ fontSize: 20 }} onClick={() => handleLike()}>
            👏
            <Badge
              style={{ position: 'absololute', bottom: -20, left: -10, color: 'green' }}
              showZero
              badgeContent={likesAndDislikes.likes}
            />
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ fontSize: 20 }} onClick={handleDislike}>
            😡
            <Badge
              style={{ position: 'absololute', bottom: -20, left: -12, color: 'red' }}
              showZero
              badgeContent={likesAndDislikes.dislikes}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid item pr={5} py={2} xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default LastDowloadsCard;
