// React
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { RegisterForm } from '../sections/auth/register';
import AuthSocial from '../sections/auth/AuthSocial';
import UploadForm from '../components/uploadFormResumit/uploadForm';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';
import { UploadingResumitContext } from '../context/uploadingResumit.context';
import { UserContext } from '../context/user.context';
import LoginFirst from '../components/loginFirst/LoginFirst';
import { selectUploadingResumit } from '../store/resumit/resumit.selector';
// ----------------------------------------------------------------------

const EditResumit = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const uploadingResumit = useSelector(selectUploadingResumit);
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  return (
    <>
      {currentUser ? (
        <Grid container spacing={1}>
          <Grid item md={12}>
            <Typography paddingLeft={1} variant="h4" gutterBottom>
              Edita tu Resumit 🚀
            </Typography>
          </Grid>
          <Grid item md={8}>
            <UploadForm isEdit id={id} />
          </Grid>
          <Grid item md={3} marginLeft={4}>
            <Typography textAlign={'center'} paddingBottom={1} variant="h4">
              Tu Resumit 😍
            </Typography>
            <ShopProductCard product={uploadingResumit} isInUploadingPage />
          </Grid>
        </Grid>
      ) : (
        <LoginFirst />
      )}
    </>
  );
};

export default EditResumit;
