export const universidades = [
  'Otro',
  'Pontificia Universidad Católica de Chile',
  'Universidad de Chile',
  'Universidad de los Andes',
  'Universidad del Desarrollo',
  'Universidad Adolfo Ibáñez',
  'Universidad Andrés Bello',
  'Universidad de Santiago de Chile',
  'Universidad Diego Portales',
  'Universidad Técnica Federico Santa María',
  'Universidad Católica de Valparaíso',
  'Universidad Austral de Chile',
  'Universidad de Concepción',
  'Universidad de Valparaíso',
  'Universidad de Talca',
  'Universidad de La Frontera',
  'Universidad de Antofagasta',
  'Universidad de La Serena',
  'Universidad de Playa Ancha',
  'Universidad de Tarapacá',
  'Universidad de Atacama',
  'Universidad de Magallanes',
  'Universidad Arturo Prat',
  'Universidad Católica del Norte',
  'Universidad Católica de Temuco',
  'Universidad Católica de la Santísima Concepción',
];
