import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AuthSocial from '../../sections/auth/AuthSocial';

const LoginFirst = () => {
  return (
    <Stack direction={'column'} spacing={4} marginX={2}>
      <Typography textAlign={'center'} variant="h3">
        Inicia sesión para ver este contenido!
      </Typography>
      <Box>
        <AuthSocial />
      </Box>
    </Stack>
  );
};

export default LoginFirst;
