import { useState, useContext, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Button } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import AportadoresBox from '../components/aportadoresBox/aportadoresBox';

import { getDataOfUser, getOneResumit, setDineroPorRetirar } from '../firebase/firebase.utils';
import LoginFirst from '../components/loginFirst/LoginFirst';
import Spinner from '../components/spinner/spinner';
import DatosBancariosForm from '../components/datosBancariosForm/datosBancariosForm';
import CopyToClipboardButton from '../components/copyToClipboar';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [myUploadedFilesIds, setUploadedFilesIds] = useState([]);
  const [myUploadedFilesComplete, setUploadedFilesComplete] = useState([]);
  const [datosBancarios, setDatosBancarios] = useState([]);
  const [dineroTotal, setDineroTotal] = useState(0);
  const [resumitsTotalVendidos, setResumitsTotalVendidos] = useState(0);
  const [dineroPorRecibir, setDineroPorRecibir] = useState(0);
  const [labelsGraphTorta, setLabelsGraphTorta] = useState([]);
  const [labelsGraphDescargasResumit, setLabelsGraphDescargasResumit] = useState([]);
  const [labelsGraphIngresosResumit, setLabelsGraphIngresosResumit] = useState([]);
  const [ventasDelMes, setVentasDelMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [ingresosDelMes, setIngresosDelMes] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [cargando, setCargando] = useState(true);

  const getAndSetData = async () => {
    if (currentUser) {
      const dataSnapshot = await getDataOfUser(currentUser);
      setUploadedFilesIds(dataSnapshot.data().myUploadedFiles);
      setDatosBancarios(dataSnapshot.data().datosBancarios);
      let dineroTotalLocal = 0;
      let resumitsTotalVendidosLocal = 0;
      const labelsLocalGraphTorta = [];
      const labelsLocalGraphDescargasResumit = [];
      const labelsLocalIngresosResumit = [];
      const ventasDelMesLocal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const ingresosDelMesLocal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      setDineroPorRecibir(dataSnapshot.data().dineroPorRetirar);
      if (dataSnapshot.data().myUploadedFiles.length !== 0) {
        dataSnapshot.data().myUploadedFiles.map(async (id) => {
          setCargando(true);
          const resumitSnapshot = await getOneResumit(id);
          setCargando(false);
          dineroTotalLocal += resumitSnapshot.dineroGenerado;
          resumitsTotalVendidosLocal += resumitSnapshot.downloadsCuantity;
          labelsLocalGraphTorta.push({ label: resumitSnapshot.name, value: resumitSnapshot.downloadsCuantity });
          labelsLocalGraphDescargasResumit.push({
            label: resumitSnapshot.name,
            value: resumitSnapshot.downloadsCuantity,
          });
          labelsLocalIngresosResumit.push({ label: resumitSnapshot.name, value: resumitSnapshot.dineroGenerado });
          setUploadedFilesComplete((before) => [...before, resumitSnapshot]);
          setDineroTotal(dineroTotalLocal);
          setResumitsTotalVendidos(resumitsTotalVendidosLocal);
          // setDineroPromedio(dineroTotalLocal / dataSnapshot.data().myUploadedFiles.length);
          setLabelsGraphTorta(labelsLocalGraphTorta);
          setLabelsGraphDescargasResumit(labelsLocalGraphDescargasResumit);
          setLabelsGraphIngresosResumit(labelsLocalIngresosResumit);
          resumitSnapshot.downloadsData.forEach(async (data) => {
            if (data.dayOfPurchase) {
              const monthOfPurchase = await data.dayOfPurchase.toDate().getMonth();
              ventasDelMesLocal[monthOfPurchase - 1] += 1;
              ingresosDelMesLocal[monthOfPurchase - 1] += parseInt(data.precioComprado, 10);
              setVentasDelMes(ventasDelMesLocal);
              setIngresosDelMes(ingresosDelMesLocal);
            }
          });
        });
      } else {
        setCargando(false);
      }
    }
  };

  const conCarga = async () => {
    await getAndSetData();
  };
  useEffect(() => {
    conCarga();
  }, [currentUser]);

  return (
    <Page title="Dashboard">
      {currentUser ? (
        <>
          {cargando ? (
            <Spinner />
          ) : (
            <Container maxWidth="xl">
              <Grid container paddingBottom={3} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4">Hola Resumiter! Aqui tienes tus estadisticas</Typography>
                </Grid>

                <Grid item xs={12} md={3}>
                  <CopyToClipboardButton
                    isNotFullWidth
                    link={`resumit.cl/resumiter/${currentUser.uid}`}
                    text="Copiar link del perfil"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DatosBancariosForm currentUser={currentUser} datosBancarios={datosBancarios} />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    title="Resumits Vendidos"
                    color="success"
                    total={resumitsTotalVendidos}
                    icon={'carbon:book'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    title="Ingresos Totales"
                    isMoney
                    total={dineroTotal}
                    color="info"
                    icon={'dashicons:money-alt'}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    isMoney
                    title="Dinero por recibir"
                    total={dineroPorRecibir}
                    color="info"
                    icon={'tabler:pig-money'}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    title="Rating Total"
                    isRating
                    total={4.3}
                    color="warning"
                    icon={'codicon:star-half'}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <AppWebsiteVisits
                    title="Ingresos por mes"
                    chartLabels={[
                      '01/01/2023',
                      '02/01/2023',
                      '03/01/2023',
                      '04/01/2023',
                      '05/01/2023',
                      '06/01/2023',
                      '07/01/2023',
                      '08/01/2023',
                      '09/01/2023',
                      '10/01/2023',
                      '11/01/2023',
                      '12/01/2023',
                    ]}
                    chartData={[
                      {
                        name: 'Ventas del mes',
                        type: 'area',
                        fill: 'gradient',
                        data: ingresosDelMes,
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AportadoresBox />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <AppWebsiteVisits
                    title="Resumits vendidos por mes"
                    chartLabels={[
                      '01/01/2023',
                      '02/01/2023',
                      '03/01/2023',
                      '04/01/2023',
                      '05/01/2023',
                      '06/01/2023',
                      '07/01/2023',
                      '08/01/2023',
                      '09/01/2023',
                      '10/01/2023',
                      '11/01/2023',
                      '12/01/2023',
                    ]}
                    chartData={[
                      {
                        name: 'Ventas del mes',
                        type: 'area',
                        fill: 'gradient',
                        data: ventasDelMes,
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                  <AppCurrentVisits
                    title="Tus Resumits más Populares"
                    chartData={labelsGraphTorta}
                    chartColors={[
                      theme.palette.primary.main,
                      theme.palette.chart.blue[0],
                      theme.palette.chart.violet[0],
                      theme.palette.chart.yellow[0],
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <AppConversionRates
                    title="Cantidad de descargas por Resumit"
                    subheader={`Total de descargas: ${resumitsTotalVendidos}`}
                    chartData={labelsGraphDescargasResumit}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppConversionRates
                    title="Ingresos por Resumit"
                    subheader={`Ingresos Totales: ${dineroTotal}`}
                    chartData={labelsGraphIngresosResumit}
                  />
                </Grid>

                {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

                {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

                {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

                {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
              </Grid>
            </Container>
          )}
        </>
      ) : (
        <LoginFirst />
      )}
    </Page>
  );
}
