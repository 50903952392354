/* eslint-disable arrow-body-style */
import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';
import { DefaultResumit } from '../assets/defaultResumit';
import { getOneResumit, getDataOfUser } from '../firebase/firebase.utils';
import { UserContext } from '../context/user.context';
import LoginFirst from '../components/loginFirst/LoginFirst';
import Spinner from '../components/spinner/spinner';
import CardProfileResumiter from '../components/cardProfileResumiter';

const ResumiterPage = () => {
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);
  const [totalDownloads, setTotalDownloads] = useState(0);
  const [ratingTotal, setRatingTotal] = useState(0);
  const [resumitsConRating, setResumitsConRating] = useState(0);
  const [myUploadedFilesIds, setUploadedFilesIds] = useState([]);
  const [myUploadedFilesComplete, setUploadedFilesComplete] = useState([]);
  const [dataResumiter, setDataResumiter] = useState({});
  const [cargando, setCargando] = useState(true);

  const getAndSetData = async () => {
    const dataSnapshot = await getDataOfUser(id);
    let totalDownloadsCount = 0;
    let sumaDeRatings = 0;
    let sumaResumitsConRating = 0;
    if (dataSnapshot.data()) {
      setDataResumiter(dataSnapshot.data());
      setUploadedFilesIds(dataSnapshot.data().myUploadedFiles);
      const checkIfAlreadyExist = [myUploadedFilesIds];
      if (dataSnapshot.data().myUploadedFiles.length !== 0) {
        dataSnapshot.data().myUploadedFiles.map(async (id) => {
          if (!checkIfAlreadyExist.includes(id)) {
            checkIfAlreadyExist.push(id);
            setCargando(true);
            const resumitSnapshot = await getOneResumit(id);
            setCargando(false);
            setUploadedFilesComplete((before) => [...before, resumitSnapshot]);
            totalDownloadsCount += resumitSnapshot.downloadsCuantity;
            setTotalDownloads(totalDownloadsCount);
            if (resumitSnapshot.ratingTotal) {
              sumaDeRatings += resumitSnapshot.ratingTotal;
              sumaResumitsConRating += 1;
              setResumitsConRating(sumaResumitsConRating);
              setRatingTotal(sumaDeRatings);
            }
          }
        });
        setTotalDownloads(totalDownloadsCount);
      } else {
        setCargando(false);
      }
    }
  };

  const conCarga = async () => {
    await getAndSetData();
  };

  useEffect(() => {
    conCarga();
  }, []);

  return (
    <>
      <>
        {cargando ? (
          <Spinner />
        ) : (
          <Grid container spacing={3} p={3}>
            <Grid item xs={12}>
              <CardProfileResumiter
                name={dataResumiter.displayName}
                uploadedResumits={myUploadedFilesComplete.length}
                totalDownloads={totalDownloads}
                rating={resumitsConRating > 0 ? ratingTotal / resumitsConRating : 0}
              />
            </Grid>
            <Grid item xs={12}>
              <h2> Resumits de {dataResumiter.displayName}</h2>
            </Grid>
            {myUploadedFilesComplete.length > 0 ? (
              <>
                {myUploadedFilesComplete.map((resumit) => (
                  <Grid key={resumit.id} item xs={12} sm={6} md={3}>
                    <ShopProductCard product={resumit} />
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item>
                <h3>Este perfil no tiene resumits ....</h3>
              </Grid>
            )}
          </Grid>
        )}
      </>
    </>
  );
};

export default ResumiterPage;
