// material
import { Stack, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

// component
import Iconify from '../../components/Iconify';
import { signInWithGooglePopup, signOutUser } from '../../firebase/firebase.utils';
import { UserContext } from '../../context/user.context';

// ----------------------------------------------------------------------

export default function AuthSocial({ noDescription }) {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);

  const logGoogleUser = async () => {
    await signInWithGooglePopup();
    // navigate('/dashboard/products');
  };

  return (
    <>
      {/* {currentUser ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className="nav-link" onClick={signOutUser}>
          Sign Out
        </span>
      ) : ( */}
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={logGoogleUser}>
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
          {noDescription ? (
            <></>
          ) : (
            <Typography paddingLeft={2} variant="body1">
              Iniciar sesión con Google
            </Typography>
          )}
        </Button>
        {/* 
          <Button fullWidth size="large" color="inherit" variant="outlined">
            <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
          </Button>

          <Button fullWidth size="large" color="inherit" variant="outlined">
            <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
          </Button> */}
      </Stack>
      {/* )} */}
    </>
  );
}
