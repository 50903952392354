/* eslint-disable react/prop-types */
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
// eslint-disable-next-line import/no-unresolved
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
// eslint-disable-next-line arrow-body-style
const SearchBar = ({ setSearchQuery, dropdown, name }) => {
  const [isSelect, setIsSelect] = useState(true);
  return (
    <form>
      <TextField
        fullWidth
        required
        id="select"
        select={isSelect}
        name={name}
        label={name}
        // value={currency}
        onInput={(e) => {
          if (e.target.value === 'buscarOtro') {
            setIsSelect(false);
          } else {
            setSearchQuery(e.target.value.toLowerCase());
          }
        }}
        SelectProps={{
          native: true,
        }}
        // helperText="Esco"
      >
        {dropdown.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
        <option key="buscar otro" value="buscarOtro">
          Buscar otro
        </option>
      </TextField>
    </form>
  );
};

export default SearchBar;
