// React
import { useEffect } from 'react';
// Router
import { Navigate, useRoutes } from 'react-router-dom';
// Redux
import { useDispatch } from 'react-redux';
import { setCurrentUser } from './store/user/user.action';
import { setCurrentUserFB } from './store/userFB/userFB.action';
import { setHistorial } from './store/historialFB/historialFB.action';
// Firebase
import {
  onAuthStateChangedListener,
  createUserDocumentFromAuth,
  getDataOfUser,
  getHistorial,
} from './firebase/firebase.utils';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import UploadResumen from './pages/UploadResumen';
import ResumitFullPage from './pages/ResumitFull';
import MyResumitsDownloaded from './pages/MyResumitsDownloaded';
import SuccessPaymentPage from './pages/Payments/SuccessPayment';
import FailurePaymentPage from './pages/Payments/FailurePayment';
import MyResumitsUploaded from './pages/MyResumitsUploaded';
import EditResumit from './pages/EditResumen';
import ResumiterPage from './pages/ResumiterPage';
// ----------------------------------------------------------------------

export const Router = () => {
  const dispatch = useDispatch();
  const setHistorialToRedux = async () => {
    const historialData = await getHistorial();
    console.log(historialData);
    dispatch(setHistorial(historialData));
  };
  const setDataUserToRedux = async (user) => {
    if (user) {
      const dataUserFB = await getDataOfUser(user.uid);
      console.log(dataUserFB.data());
      dispatch(setCurrentUserFB(dataUserFB.data()));
    } else {
      dispatch(setCurrentUserFB(undefined));
    }
  };
  // const myDispatch = async (dispatchMethod, userForSet) => {
  //   await dispatchMethod(setCurrentUser(userForSet));
  // };
  useEffect(() => {
    setHistorialToRedux();
    const unSubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }
      dispatch(setCurrentUser(user));
      setDataUserToRedux(user);
    });
    return unSubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/resumits" /> },
        { path: '404', element: <NotFound /> },
        // { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'resumits', element: <Products /> },
        { path: 'myResumitsUploaded', element: <MyResumitsUploaded /> },
        { path: 'upload', element: <UploadResumen /> },
        { path: 'edit/:id', element: <EditResumit /> },
        { path: 'resumits/:id', element: <ResumitFullPage /> },
        { path: 'resumiter/:id', element: <ResumiterPage /> },
        { path: 'myResumitsDownloaded', element: <MyResumitsDownloaded /> },
        { path: 'payment/success', element: <SuccessPaymentPage /> },
        { path: 'payment/failure', element: <FailurePaymentPage /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
};
