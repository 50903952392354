import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
    },
    cagado0: {
      main: '#00d4a6',
    },
    cagado1: {
      main: '#0038d4',
    },
    cagado2: {
      main: '#fc6d00',
    },
    cagado3: {
      main: '#ff2200',
    },
    cagado4: {
      main: '#ff0000',
    },
  },
});

export default theme;
