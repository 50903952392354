import * as React from 'react';
import { useState } from 'react';
import * as Rut from 'rutjs';
import { format } from 'rut.js';
import { Grid, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { setDatosBancariosInUserDoc } from '../../firebase/firebase.utils';
// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DatosBancariosForm({ currentUser, datosBancarios }) {
  const [open, setOpen] = React.useState(false);
  const [datos, setDatos] = useState(datosBancarios);
  const rut = new Rut('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setDatos((values) => ({ ...values, [name]: value }));
  };

  const notifySuccess = () =>
    toast.success('Datos Bancarios agregados correctamente :)', {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyFailure = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const handleUpload = (event) => {
    event.preventDefault();
    if (!datos.banco) {
      datos.banco = 'SCOTIABANK';
    }
    if (!datos.tipoCuenta) {
      datos.tipoCuenta = 'Cuenta Corriente';
    }
    if (!datos.nombreApellido) {
      // eslint-disable-next-line no-alert
      notifyFailure('Rellena tu nombre para agregar tus datos!');
    } else if (!datos.rut) {
      notifyFailure('Rellena tu Rut para agregar tus datos!');
    } else if (!datos.email) {
      notifyFailure('Rellena tu email para agregar tus datos!');
    } else if (!datos.banco) {
      notifyFailure('Selecciona tu banco para agregar tus datos!');
    } else if (!datos.tipoCuenta) {
      notifyFailure('Selecciona tu tipo de cuenta para agregar tus datos!');
    } else if (!datos.numeroCuenta) {
      notifyFailure('Rellena tu numero de cuenta para agregar tus datos!');
    } else {
      setDatosBancariosInUserDoc(currentUser, datos);
      notifySuccess();
      handleClose();
    }
  };

  return (
    <div>
      <ToastContainer />
      <Button onClick={() => handleClickOpen()} variant="contained">
        Agregar Datos Bancarios
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Rellena tus Datos Bancarios'}</DialogTitle>
        <DialogContent>
          <Grid container paddingTop={2} spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                name="nombreApellido"
                defaultValue={datosBancarios ? datosBancarios.nombreApellido : ''}
                required
                // defaultValue={isEdit ? editResumit.name : ''}
                onChange={handleChangeForm}
                label={'Nombre y Apellido'}
                placeholder="Mikasa Ackermann"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                name="rut"
                required
                defaultValue={datosBancarios ? datosBancarios.rut : ''}
                onChange={(event) => {
                  rut.setRut(format(event.target.value));

                  event.target.value = rut.getNiceRut();
                  handleChangeForm(event);
                }}
                label={'RUT: 11.111.111-1'}
                placeholder="11.111.111-1"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="email"
                name="email"
                required
                defaultValue={datosBancarios ? datosBancarios.email : ''}
                onChange={handleChangeForm}
                label={'Email'}
                placeholder="armin.arlet@gmail.com"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                name="banco"
                select
                required
                onChange={handleChangeForm}
                defaultValue={datosBancarios ? datosBancarios.banco : ''}
                label={'Banco'}
                placeholder="SCOTIABANK"
                SelectProps={{
                  native: true,
                }}
              >
                <option value={'SCOTIABANK'}>SCOTIABANK</option>
                <option value={'BANCO DEL DESARROLLO'}>BANCO DEL DESARROLLO</option>
                <option value={'BANCO BICE'}>BANCO BICE</option>
                <option value={'BANCO DE CHILE'}>BANCO DE CHILE</option>
                <option value={'BANCO CONSORCIO'}>BANCO CONSORCIO</option>
                <option value={'BANCO DE CREDITO E INVERSIONES'}>BANCO DE CREDITO E INVERSIONES</option>
                <option value={'BANCO DEL ESTADO DE CHILE'}>BANCO DEL ESTADO DE CHILE</option>
                <option value={'BANCO FALABELLA'}>BANCO FALABELLA</option>
                <option value={'BANCO INTERNACIONAL'}>BANCO INTERNACIONAL</option>
                <option value={'BANCO ITAU'}>BANCO ITAU</option>
                <option value={'BANCO PARIS'}>BANCO PARIS</option>
                <option value={'BANCO RIPLEY'}>BANCO RIPLEY</option>
                <option value="BANCO SANTANDER-SANTIAGO">BANCO SANTANDER-SANTIAGO</option>
                <option value="BANCO SECURITY">BANCO SECURITY</option>
                <option value="CITIBANK N.A">CITIBANK N.A</option>
                <option value="COOPEUCH">COOPEUCH</option>
                <option value="CORPBANCA">CORPBANCA</option>
                <option value="HSBC BANK CHILE">HSBC BANK CHILE</option>
                <option value="J.P. MORGAN CHASE BANK">J.P. MORGAN CHASE BANK</option>
                <option value="MERCADO PAGO EMISORA S.A">MERCADO PAGO EMISORA S.A</option>
                <option value="PREPAGO LOS HEROES">PREPAGO LOS HEROES</option>
                <option value="RABOBANK">RABOBANK</option>
                <option value="TAPP CAJA LOS ANDES">TAPP CAJA LOS ANDES</option>
                <option value="TENPO PREPAGO">TENPO PREPAGO</option>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                name="tipoCuenta"
                required
                select
                onChange={handleChangeForm}
                defaultValue={datosBancarios ? datosBancarios.tipoCuenta : ''}
                label={'Tipo de Cuenta'}
                placeholder="Cuenta Corriente"
                SelectProps={{
                  native: true,
                }}
              >
                <option value={'Cuenta Corriente'}>Cuenta Corriente</option>
                <option value={'Cuenta Vista'}>Cuenta Vista</option>
                <option value={'Cuenta Ahorro'}>Cuenta Ahorro</option>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                name="numeroCuenta"
                required
                defaultValue={datosBancarios ? datosBancarios.numeroCuenta : ''}
                onChange={handleChangeForm}
                label={'Numero de Cuenta'}
                placeholder="1111111111"
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={handleUpload} type="submit" variant="contained">
                Agregar Datos Bancarios
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
