import { useState, useEffect, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';
import { getDataOfUser } from '../firebase/firebase.utils';
// Basicamente es para porder usar un state en cualquier lugar, el provider es ek father de la app

export const UserDataFBContext = createContext({
  dataOfUserFB: null,
  setDataOfUser: () => Promise,
});

export const UserDataFBProvider = ({ children }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [dataOfUserFB, setDataOfUser] = useState(null);
  const value = { dataOfUserFB, setDataOfUser };
  useEffect(() => {
    if (currentUser) {
      getDataOfUser(currentUser).then((result) => {
        setDataOfUser(result);
      });
    }
  }, [currentUser]);

  return <UserDataFBContext.Provider value={value}>{children}</UserDataFBContext.Provider>;
};
