import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Stack, Typography, Box, LinearProgress, CardMedia, Card, TextField, Divider } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Firebase
import { uploadBytesResumable, ref } from 'firebase/storage';
import {
  getProfilePic,
  setProfilePicIdInUserFB,
  setTextDescriptionInUserFB,
  storage,
} from '../firebase/firebase.utils';
// Components
import { levelsCagados } from './cagadosProgressBar';

// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogEditProfile({ isOpen, fn, url, fnChangeFilePicLocal, fnChangeDescription }) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentUserFB = useSelector((state) => state.userFBData.currentUserFB);
  const [profilePicFile, setProfilePicFile] = React.useState('');
  const [textDescription, setTextDescription] = React.useState('');
  const [profilePicFilePreview, setProfilePicFilePreview] = React.useState(url);

  React.useEffect(() => {
    setProfilePicFilePreview(url);
    if (currentUser) {
      if (currentUserFB.profileDescription) {
        setTextDescription(currentUserFB.profileDescription);
      }
    }
  }, [url]);

  const handleChangeDescription = (e) => {
    setTextDescription(e.target.value);
  };
  const handleOnClickNewDescription = async () => {
    await setTextDescriptionInUserFB(currentUser, textDescription);
    fnChangeDescription(textDescription);
    notifySuccess('Descripción actualizada correctamente');
  };

  const handleChangeProfilePic = (e) => {
    setProfilePicFile(e.target.files[0]);
    console.log(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.readyState === 2) {
        setProfilePicFilePreview(reader.result);
        fnChangeFilePicLocal(reader.result);
        // setResumit((values) => ({ ...values, [name]: reader.result }));
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    const { name } = e.target;
    const storageRefProfilePic = ref(storage, `/files/${currentUser.uid}/profilePic`);
    const uploadTaskProfilePic = uploadBytesResumable(storageRefProfilePic, e.target.files[0]);
    uploadTaskProfilePic.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        // update progress
      },
      (err) => {
        console.log(err);
        // setEnableButton(true);
        // notifyFailure();
      },
      async () => {
        await setProfilePicIdInUserFB(currentUser);
        // await createResumitDocument(resumit);
        // await uploadFileAndSaveInUserFS(currentUser, resumit);
        notifySuccess('Foto de perfil cambiada correctamente! :)');
      }
    );
  };
  const notifySuccess = (text) =>
    toast.success(text, {
      position: 'top-right',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => fn(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign={'center'}>{'Editar Perfil'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignContent="center" alignItems={'center'}>
            <img alt="profilePic" height={200} width={200} style={{ borderRadius: 10 }} src={profilePicFilePreview} />
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: 'image/*' }}
              name="coverPreview"
              required
              onChange={handleChangeProfilePic}
            />
            <Typography variant="h6">Editar Descripción</Typography>
            <TextField
              style={{ padding: 5 }}
              multiline
              defaultValue={textDescription}
              onChange={handleChangeDescription}
              placeholder="Estudiante ING UC: 
                Resúmenes de:
                - Cálculo
                - Dinámica
                - Biología
                Y muchos más, espero que les sirva :)"
            />
            <Button onClick={handleOnClickNewDescription} style={{}} color="success" variant="contained">
              Guardar Nueva Descripción
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => fn(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
