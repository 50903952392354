import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button, Stack, Card } from '@mui/material';
import ShopProductCard from '../../sections/@dashboard/products/ProductCard';
import { DefaultResumit } from '../../assets/defaultResumit';
import { UserContext } from '../../context/user.context';
import { getDataOfUser, getOneResumit, setActionInUserDoc } from '../../firebase/firebase.utils';
import Spinner from '../../components/spinner/spinner';

// eslint-disable-next-line arrow-body-style
const FailurePaymentPage = () => {
  const [resumit, setResumit] = useState(DefaultResumit);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [cargando, setCargando] = useState(true);

  const setData = async () => {
    const dataOfUser = await getDataOfUser(currentUser);
    const { compraID, precioCompra } = dataOfUser.data().action;
    setCargando(true);
    const resumitLocal = await getOneResumit(compraID);
    setCargando(false);
    setResumit(resumitLocal);
    setActionInUserDoc(currentUser, compraID, 'checkout', 'failure', precioCompra);
  };
  useEffect(() => {
    if (currentUser) {
      setData();
    }
  }, [currentUser]);
  return (
    <>
      {cargando ? (
        <Spinner />
      ) : (
        <Grid spacing={3} container>
          <Grid item xs={12}>
            <Typography align="center" variant="h2" color="red">
              No pudimos procesar tu pago :(
            </Typography>
          </Grid>
          <Grid item paddingTop={4} md={3}>
            <ShopProductCard product={resumit} isInUploadingPage />
          </Grid>
          <Grid item md={8}>
            <Card>
              <Stack
                alignItems="center"
                paddingBottom={3}
                spacing={3}
                paddingLeft={4}
                paddingRight={4}
                direction="column"
              >
                <Typography paddingTop={3} variant="body1">
                  Hubo un error con tu pago, porfavor intentalo nuevamente!
                </Typography>
                <a href={`/resumits/${resumit.id}`} style={{ textDecoration: 'none' }}>
                  <Button variant="contained">Intentar Nuevamente</Button>
                </a>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FailurePaymentPage;
