/* eslint-disable arrow-body-style */
import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import ResumitUploadedCard from '../sections/@dashboard/products/ResumitUploadedCard';
import { DefaultResumit } from '../assets/defaultResumit';
import { getOneResumit, getDataOfUser } from '../firebase/firebase.utils';
import { UserContext } from '../context/user.context';
import LoginFirst from '../components/loginFirst/LoginFirst';
import Spinner from '../components/spinner/spinner';

const MyResumitsUploaded = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [myUploadedFilesIds, setUploadedFilesIds] = useState([]);
  const [myUploadedFilesComplete, setUploadedFilesComplete] = useState([]);
  const [cargando, setCargando] = useState(true);

  const getAndSetData = async () => {
    if (currentUser) {
      const dataSnapshot = await getDataOfUser(currentUser);
      if (dataSnapshot.data().myUploadedFiles.length !== 0) {
        setUploadedFilesIds(dataSnapshot.data().myUploadedFiles);
        dataSnapshot.data().myUploadedFiles.map(async (id) => {
          setCargando(true);
          const resumitSnapshot = await getOneResumit(id);
          setCargando(false);
          setUploadedFilesComplete((before) => [...before, resumitSnapshot]);
        });
      } else {
        setCargando(false);
      }
    }
  };

  useEffect(() => {
    getAndSetData();
  }, [currentUser]);

  return (
    <>
      {currentUser ? (
        <>
          {cargando ? (
            <Spinner />
          ) : (
            <Grid container spacing={3} p={3}>
              <Grid item xs={12}>
                <h1> Mis Resumits Subidos</h1>
              </Grid>
              {myUploadedFilesComplete.length > 0 ? (
                <>
                  {myUploadedFilesComplete.map((resumit) => (
                    <>
                      {resumit ? (
                        <Grid key={resumit.id} item xs={12} sm={6} md={3}>
                          <ResumitUploadedCard product={resumit} />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <Grid item>
                  <h3>No has subido resumenes aún ....</h3>
                </Grid>
              )}
            </Grid>
          )}
        </>
      ) : (
        <LoginFirst />
      )}
    </>
  );
};

export default MyResumitsUploaded;
