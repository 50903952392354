// React
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { RegisterForm } from '../sections/auth/register';
import AuthSocial from '../sections/auth/AuthSocial';
import UploadForm from '../components/uploadFormResumit/uploadForm';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';
import { UploadingResumitContext } from '../context/uploadingResumit.context';
import { UserContext } from '../context/user.context';
import LoginFirst from '../components/loginFirst/LoginFirst';
import { selectUploadingResumit } from '../store/resumit/resumit.selector';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 250,
  minWidth: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(10, 0, 0, 0),
}));

const ContentStyle = styled(Container)(({ theme }) => ({
  maxWidth: 800,
  margin: theme.spacing(4, 0, 0, 0),
  minHeight: '50vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  //   padding: theme.spacing(5, 0, 0, 1),
}));

// ----------------------------------------------------------------------

export default function UploadResumen() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const uploadingResumit = useSelector(selectUploadingResumit);
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'sm');

  return (
    <>
      {currentUser ? (
        <Grid container justifyContent={'center'} columnSpacing={5} p={2}>
          <Grid item md={12}>
            <Typography paddingLeft={2} variant="h3" gutterBottom>
              Publica tu Resumit 🚀
            </Typography>
          </Grid>
          <Grid item md={8}>
            <UploadForm />
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography textAlign={'center'} paddingBottom={1} variant="h4">
              Asi se verá tu Resumit 😍
            </Typography>
            <ShopProductCard product={uploadingResumit} chupalo isInUploadingPage />
          </Grid>
        </Grid>
      ) : (
        <LoginFirst />
      )}
    </>
  );
}
