import { HISTORIAL_ACTION_TYPES } from './historialFB.types';

const INITIAL_STATE = {
  historial: null,
};

// eslint-disable-next-line default-param-last
export const historialReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  // Esto se va a activar siempre q se cambie el state, y cuando no sea currentUser, este devuelvo lo q ya tenía
  switch (type) {
    case HISTORIAL_ACTION_TYPES.SET_HISTORIAL:
      return { ...state, historial: payload };
    default:
      return state;
  }
};
