/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getDownloadURL } from 'firebase/storage';
// material
import { Box, Card, Link, Typography, Stack, Grid, Button, CardMedia, Rating, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import { getCoverFB, updateResumitImageUrl } from '../../../firebase/firebase.utils';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

// ResumitFullCard.propTypes = {
//   product: PropTypes.object,
// };

export default function ResumitFullCard({ product, isInUploadingPage }) {
  const {
    name,
    universidad,
    curso,
    coverPreview,
    userName,
    status,
    id,
    userId,
    description,
    downloadsCuantity,
    ratingTotal,
    ratings,
    isPremium,
  } = product;
  const [cover, setCover] = useState('');
  const navigate = useNavigate();
  const pruebaQlia = async () => {
    if (product.coverPreviewUrl) {
      setCover(product.coverPreviewUrl);
    } else {
      const prueba = await getCoverFB(userId, id);
      prueba.items.forEach(async (itemRef) => {
        if (itemRef.name === id) {
          const url = await getDownloadURL(itemRef);
          setCover(url);
          updateResumitImageUrl(product, url);
        }
      });
    }
  };

  useEffect(() => {
    if (!isInUploadingPage) {
      pruebaQlia();
    }
  }, [userId]);

  return (
    <>
      <Badge
        badgeContent={
          isPremium && (
            <Box
              sx={{
                position: 'relative',
                display: 'inline-block',
                '&:hover .hover-text': {
                  visibility: 'visible',
                  opacity: 1,
                },
              }}
            >
              <Iconify icon="ic:baseline-star" sx={{ fontSize: '1.5rem' }} title="Premium" />
              <Box
                className="hover-text"
                sx={{
                  visibility: 'hidden',
                  opacity: 0,
                  width: 'auto',
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  color: 'white',
                  textAlign: 'center',
                  borderRadius: '4px',
                  padding: '2px 8px',
                  position: 'absolute',
                  zIndex: 1,
                  top: '-25px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  transition: 'opacity 0.3s',
                }}
              >
                Premium
              </Box>
            </Box>
          )
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiBadge-badge': {
            top: 30,
            left: 30,
            color: 'gold',
          },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card sx={{ display: 'flex', m: 1 }}>
          <Stack
            direction={'row'}
            alignItems="center"
            paddingX={1.5}
            style={{ position: 'absolute', top: 5, right: 10, backgroundColor: '#faaf00', borderRadius: 20 }}
          >
            <Typography fontSize={16} fontWeight="bold" color={'white'}>
              {downloadsCuantity}
            </Typography>
            <Iconify icon="ic:baseline-file-download" color="white" fontSize="1.2rem" />
          </Stack>
          <CardMedia component="img" sx={{ width: 151 }} image={cover} alt="Live from space album cover" />
          <Stack direction={'column'} spacing={0.5} sx={{ p: 3 }}>
            <Link to="#" color="inherit" underline="hover" component={RouterLink}>
              <Typography variant="h6" noWrap>
                {name}
              </Typography>
            </Link>
            <Typography variant="caption" noWrap display="block">
              {userName}
            </Typography>
            <Typography variant="caption" noWrap display="block">
              {universidad}
            </Typography>
            <Typography variant="caption" noWrap display="block">
              {curso}
            </Typography>
            {ratingTotal ? (
              <Stack spacing={2} direction={'row'} alignItems={'center'} style={{ marginTop: 10 }}>
                <Rating value={ratingTotal} readOnly />
                <Typography fontSize={14} fontWeight="bold">
                  {ratings.length}👤
                </Typography>
              </Stack>
            ) : (
              <></>
            )}
            <Button
              onClick={() => navigate(`/resumiter/${userId}`)}
              size={'small'}
              style={{ width: 100, height: 30, marginTop: 10 }}
              variant="contained"
            >
              Ver perfil
            </Button>
          </Stack>
        </Card>
        <Card sx={{ p: 3, marginTop: 2, marginX: 1 }}>
          <Typography textAlign={'center'} variant="h6" noWrap>
            Contenido del Resumit
          </Typography>
          <Typography mt={1} variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography>
        </Card>
      </Badge>
    </>
  );
}
