/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
import {
  Card,
  CardHeader,
  Grid,
  Stack,
  Typography,
  Divider,
  Rating,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useState, useRef, useContext, useEffect } from 'react';
import { commentInResumit } from '../../firebase/firebase.utils';
import { UserContext } from '../../context/user.context';

const typeOfComments = ['Dudas', 'Calificar'];
const labels = {
  1: 'Como la tula',
  2: 'La wea mala',
  3: 'Piola tu wea',
  4: 'Like it',
  5: 'Me salvaste el semestre',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}
const CommentsBox = ({ product }) => {
  const [comments, setComments] = useState();
  const [newComments, setNewComments] = useState();
  useEffect(() => {
    setComments(product.comments);
  });
  const [comment, setComment] = useState({});
  const [value, setValue] = useState(2);
  const [hover, setHover] = useState(-1);
  const currentUser = useSelector((state) => state.user.currentUser);
  const ref = useRef(null);
  if (currentUser) {
    if (currentUser.displayName === product.userName && !typeOfComments.includes('Autor')) {
      typeOfComments.push('Autor');
    }
  }
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setComment((values) => ({ ...values, [name]: value }));
  };

  const handleSubmitComment = async () => {
    comment.nameOfComment = currentUser.displayName;
    comment.dayOfComment = new Date();
    if (!comment.rating) {
      comment.rating = 'no';
    }
    if (!comment.typeOfComment) {
      comment.typeOfComment = 'Dudas';
    }
    commentInResumit(product, comment);
    if (newComments) {
      setNewComments((before) => [...before, comment]);
    } else {
      setNewComments([comment]);
    }
  };

  // const calificarODuda = (e) => {
  //   if (e.typeOfComment === 'Calificar') {
  //     return <Rating fullWidth getLabelText={getLabelText} readOnly name="read-only" value={e.rating} />;
  //   } else if (e.typeOfComment === 'Autor') {
  //     return (
  //       <Typography minWidth={125} variant="h4" color="error">
  //         Autor
  //       </Typography>
  //     );
  //   } else {
  //     return (
  //       <Typography minWidth={125} variant="h4" color="secondary">
  //         Dudas
  //       </Typography>
  //     );
  //   }
  // };

  // const prueba = () => {
  //   if (comment.typeOfComment === 'Calificar') {
  //     return (
  //       <Rating
  //         fullWidth
  //         getLabelText={getLabelText}
  //         //   style={{ paddingLeft: 30 }}
  //         name="rating"
  //         value={value}
  //         onChange={(event, newValue) => {
  //           setValue(newValue);
  //           handleChangeForm(event);
  //         }}
  //         onChangeActive={(event, newHover) => {
  //           setHover(newHover);
  //         }}
  //       >
  //         {value !== null && <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>}
  //       </Rating>
  //     );
  //   }
  //   if (comment.typeOfComment === 'Dudas' || comment.typeOfComment === 'Autor') {
  //     comment.rating = undefined;
  //   }
  // };
  return (
    // <Grid container spacing={3} p={3}>
    <Card>
      <Stack justifyContent="space-between" direction="row">
        {comments ? (
          <CardHeader title={`Comentarios (${comments.length})`} />
        ) : (
          <CardHeader title={`Comentarios (0)`} />
        )}
        <Button onClick={handleClick} sx={{ margin: 3, fontSize: '1.5rem', minWidth: 50, p: 0 }} variant="contained">
          +
        </Button>
      </Stack>

      {comments ? (
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {comments.map((comentarioData) => (
            <div key={comentarioData.textComment}>
              <Stack justifyContent="space-between" direction="row">
                {/* {calificarODuda(comentarioData)} */}
                <Typography align="left" variant="body1">
                  {comentarioData.textComment}
                </Typography>
                <Stack direction="column">
                  <Typography align="right" variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                    {comentarioData.nameOfComment}
                  </Typography>
                  <Typography align="right" variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                    {comentarioData.dayOfComment.toDate().toDateString()}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
            </div>
          ))}
        </Stack>
      ) : (
        <Typography variant="h1">No hay comentarios</Typography>
      )}
      {newComments ? (
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {newComments.map((comentarioData) => (
            <div key={comentarioData.textComment}>
              <Stack justifyContent="space-between" direction="row">
                {/* {calificarODuda(comentarioData)} */}
                <Typography align="left" variant="body1">
                  {comentarioData.textComment}
                </Typography>
                <Stack direction="column">
                  <Typography align="right" variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                    {comentarioData.nameOfComment}
                  </Typography>
                  <Typography align="right" variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
                    {comentarioData.dayOfComment.toDateString()}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
            </div>
          ))}
        </Stack>
      ) : (
        <Typography variant="caption" />
      )}
      {currentUser ? (
        <Stack ref={ref} paddingRight={3} justifyContent="space-between" direction="row">
          <TextField
            sx={{ maxWidth: 800, marginLeft: 2, marginBlockEnd: 1 }}
            fullWidth
            id="textComment"
            label="Tu comentario"
            name="textComment"
            multiline
            maxRows={6}
            onChange={handleChangeForm}
          />
          <Stack direction="column">
            <Button variant="contained" onClick={handleSubmitComment} sx={{ margin: '10px' }}>
              Comentar
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Typography variant="h5" p={3} textAlign={'center'}>
          {' '}
          Inicia Sesión para comentar!
        </Typography>
      )}
      <Divider />
    </Card>
    // </Grid>
  );
};

export default CommentsBox;
