// react
import { useEffect } from 'react';
// Redux
import { Provider, useDispatch } from 'react-redux';
import { store } from './store/store';
import { setCurrentUser } from './store/user/user.action';
// routes
import { Router } from './routes';
// firebase
import { onAuthStateChangedListener, createUserDocumentFromAuth } from './firebase/firebase.utils';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { UserProvider } from './context/user.context';
import { UserDataFBProvider } from './context/userDataFB.context';
import { UploadingResumitProvider } from './context/uploadingResumit.context';
import { OptionSelectedForBuyProvider } from './context/selectOptionForBuy.context';
// ----------------------------------------------------------------------

// eslint-disable-next-line arrow-body-style
export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <UserDataFBProvider>
          <UploadingResumitProvider>
            <ScrollToTop />
            <BaseOptionChartStyle />
            <Router />
          </UploadingResumitProvider>
        </UserDataFBProvider>
      </ThemeProvider>
    </Provider>
  );
};
