// React
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// MUI
import { Card, Typography, CardMedia, Stack, Grid, Button } from '@mui/material';
// Firebase
import { getDataOfUser, getProfilePic } from '../firebase/firebase.utils';
// Components
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Iconify from './Iconify';
import DialogEditProfile from './dialogEditProfile';

const CardProfileResumiter = ({ name, uploadedResumits, totalDownloads, rating }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentUserFB = useSelector((state) => state.userFBData.currentUserFB);
  const [textDescription, setTextDescription] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [profilePicFilePreview, setProfilePicFilePreview] = useState(
    'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  );
  const url = window.location.pathname;
  const id = url.substring(url.lastIndexOf('/') + 1);

  const fetchData = async () => {
    const userIdOfResumiterPage = await getDataOfUser(id);
    console.log(userIdOfResumiterPage.data());
    if (userIdOfResumiterPage.data().hasProfilePic) {
      const url = await getProfilePic(id);
      setProfilePicFilePreview(url);
    }
    if (userIdOfResumiterPage.data().profileDescription) {
      setTextDescription(userIdOfResumiterPage.data().profileDescription);
    }
    if (currentUser) {
      if (id === currentUser.uid) {
        setIsEditable(true);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <DialogEditProfile
        fnChangeFilePicLocal={setProfilePicFilePreview}
        fnChangeDescription={setTextDescription}
        isOpen={openDialog}
        fn={setOpenDialog}
        url={profilePicFilePreview}
      />
      <Card>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12} sm={6} md={3}>
            {isEditable ? (
              <Button onClick={() => setOpenDialog(true)} variant="contained" style={{ position: 'absolute' }}>
                <Iconify sx={{ fontSize: '1.2rem' }} icon={'material-symbols:edit-square-outline'} />
              </Button>
            ) : (
              <></>
            )}

            <img
              height={200}
              width={200}
              src={profilePicFilePreview}
              style={{ borderRadius: 10 }}
              alt="Live from space album cover"
            />

            <Typography mt={1} variant="h6" textAlign={'center'}>
              {name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: 'rgba(255, 220, 155, 0.5)', borderRadius: 10, minHeight: 200 }}
            mt={3}
            sm={5.6}
            md={3}
            sx={{ ml: { xs: 3, sm: 2, md: 0 } }}
          >
            <Typography variant="h5">Descripción </Typography>
            <Typography mr={1} variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {textDescription}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary title="Resumits Subidos" color="success" total={uploadedResumits} icon={'carbon:book'} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Descargas Totales"
              color="info"
              total={totalDownloads}
              icon={'material-symbols:download'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Rating Resumiter"
              color="warning"
              isRating
              total={rating}
              icon={'codicon:star-half'}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CardProfileResumiter;
