import { useState } from 'react';
import { Button, Snackbar } from '@mui/material';

const CopyToClipboardButton = ({ link, text, isNotFullWidth }) => {
  const [openClickBoard, setOpenClickboard] = useState(false);
  const handleClick = () => {
    setOpenClickboard(true);
    navigator.clipboard.writeText(link.toString());
  };
  return (
    <>
      {isNotFullWidth ? (
        <Button sx={{ marginTop: 0 }} variant="contained" onClick={handleClick}>
          {text}
        </Button>
      ) : (
        <Button fullWidth sx={{ marginTop: 3 }} variant="contained" onClick={handleClick}>
          {text}
        </Button>
      )}

      <Snackbar
        open={openClickBoard}
        onClose={() => setOpenClickboard(false)}
        autoHideDuration={2000}
        message="Copiado!"
      />
    </>
  );
};
export default CopyToClipboardButton;
