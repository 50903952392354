import { useState, createContext } from 'react';
import { DefaultResumit } from '../assets/defaultResumit';
// Basicamente es para porder usar un state en cualquier lugar, el provider es ek father de la app

export const UploadingResumitContext = createContext({
  uploadingResumit: null,
  setUploadingResumit: () => null,
});

export const UploadingResumitProvider = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [uploadingResumit, setUploadingResumit] = useState(DefaultResumit);
  const value = { uploadingResumit, setUploadingResumit };

  return <UploadingResumitContext.Provider value={value}> {children} </UploadingResumitContext.Provider>;
};
