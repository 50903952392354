import { useSelector } from 'react-redux';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'buscar resumits',
    path: '/resumits',
    icon: getIcon('fa:search'),
  },
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'mi perfil resumiter',
    path: `/resumiter`,
    icon: getIcon('mdi:user-star'),
  },
  {
    title: 'subir resumit',
    path: '/upload',
    icon: getIcon('eva:cloud-upload-fill'),
  },
  {
    title: 'mis resumits descargados',
    path: '/myResumitsDownloaded',
    icon: getIcon('eva:cloud-download-fill'),
  },
  {
    title: 'mis resumits subidos',
    path: '/myResumitsUploaded',
    icon: getIcon('fluent-emoji-high-contrast:mahjong-red-dragon'),
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },

  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
