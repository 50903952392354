import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, LinearProgress, Typography } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import CagadosProgressBar from '../../components/cagadosProgressBar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const colorsForNavbar = ['#00d4a6', '#0038d4', '#fc6d00', '#ff2200', '#ff0000'];

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const currentUserFB = useSelector((state) => state.userFBData.currentUserFB);
  let inARowNotPayingDownloads = 0;
  if (currentUserFB) {
    if (currentUserFB.inARowNotPayingDownloads) {
      inARowNotPayingDownloads = currentUserFB.inARowNotPayingDownloads;
      if (inARowNotPayingDownloads > 4) {
        inARowNotPayingDownloads = 4;
      }
    }
  }

  return (
    <RootStyle>
      <ToolbarStyle
        style={
          {
            // backgroundColor: colorsForNavbar[inARowNotPayingDownloads]
          }
        }
      >
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {/* <CagadosProgressBar /> */}

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <NotificationsPopover /> */}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
