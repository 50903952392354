/* eslint-disable no-else-return */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';
import { getAllResumits } from '../../../firebase/firebase.utils';
import SearchBar from '../../../components/search-box/search-box.component';
import { universidades } from '../../../assets/universidades';
import { cursos } from '../../../assets/cursos';
import Spinner from '../../../components/spinner/spinner';

// ----------------------------------------------------------------------

export default function ProductList() {
  const [allResumits, setResumits] = useState([]);
  const [searchQueryUniversidad, setSearchQueryUniversidad] = useState('');
  const [searchQueryCurso, setSearchQueryCurso] = useState('');
  const [cargando, setCargando] = useState(true);

  const filterDataUniversity = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => {
        return d.data().universidad.toLowerCase().includes(query.toLowerCase());
      });
    }
  };
  const filterDataCurso = (query, data) => {
    if (!query) {
      return data;
    } else {
      return data.filter((d) => {
        return d
          .data()
          .curso.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            query
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          );
      });
    }
  };
  let dataFiltered = filterDataUniversity(searchQueryUniversidad, allResumits);
  dataFiltered = filterDataCurso(searchQueryCurso, dataFiltered);
  useEffect(() => {
    setCargando(true);
    getAllResumits().then((result) => {
      setResumits(result.docs);
      setCargando(false);
    });
  }, []);
  return (
    <>
      {cargando ? (
        <Spinner />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SearchBar
              searchQuery={searchQueryUniversidad}
              setSearchQuery={setSearchQueryUniversidad}
              dropdown={universidades}
              name="Universidad"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchBar
              searchQuery={searchQueryCurso}
              name="Curso"
              setSearchQuery={setSearchQueryCurso}
              dropdown={cursos}
            />
          </Grid>

          {dataFiltered.map((product) => (
            <Grid key={product.id} marginLeft={'auto'} marginRight={'auto'} item xs={10} sm={6} md={3}>
              <ShopProductCard product={product.data()} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
