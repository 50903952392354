import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { userReducer } from './user/user.reducer';
import { optionSelectedReducer } from './optionSelectedForBuy/optionSelected.reducer';
import { resumitReducer } from './resumit/resumit.reducer';
import { userFBDataReducer } from './userFB/userFB.reducer';
import { historialReducer } from './historialFB/historialFB.reducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    optionSelected: optionSelectedReducer,
    resumit: resumitReducer,
    userFBData: userFBDataReducer,
    historial: historialReducer,
  },
  middleware: [logger],
});
